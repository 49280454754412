import React, { createRef, useEffect, useRef, useState } from "react";
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const options = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 7000
};

const BannerSlider = ({ Data, siteAccess, pageSlug = '' }: any) => {
    const startDate = moment().format('YYYY-MM-DD')
    const endDate = moment().add(1, 'weeks').format('YYYY-MM-DD')
    const [EventData, setEventData] = useState({ ...Data, load: false })
    useEffect(() => {
        if (EventData.type === 'imgRepeat') {
            if (EventData.tSlider === 'full') {
                if (pageSlug === 'home') {
                    axios.get(`${process.env.REACT_APP_API_URL}calendar/event/${siteAccess !== '' ? siteAccess + '/' : ''}${startDate}/${endDate}`).then(Eventjson => {
                        //console.log(Eventjson.data.data,Data)
                        var event = Eventjson.data.data.event.filter((e: any) => e.homepage_image !== undefined && e.homepage_image !== '')
                        //var yearlyEvent = Eventjson.data.data['yearly-event']/* .filter((e: any) => e.homepage_image !== undefined && e.homepage_image !== '') */
                        //console.log(event, yearlyEvent)
                        let aa = [] as any;
                        event.map((e: any, i: any) => {
                            aa.push({
                                s_imageurl: e.homepage_mobile_image,
                                imageurl: e.homepage_image,
                                verticalAlign: 'bottom',
                                horizontalAlign: 'left',
                                s_i_alt: e.title,
                                s_l_url: '',
                                s_header: `${moment(e.startDate.split('T')[0]).format('DD MMMM YYYY')} ${(e.startDateGujCalendar !== '') ? ` - ${e.startDateGujCalendar}` : ``}`,
                                s_title: e.title
                            })
                        })
                        setEventData({ ...EventData, image_json: aa.concat(Data.image_json), load: true })
                    })
                } else {
                    setEventData({ ...EventData, image_json: Data.image_json, load: true })
                }
            }
        }
    }, [])
    return (
        <>
            <style>{`.banner_slider .carousel {position: absolute;top: 0px;left: 0px}`}</style>
            {(EventData.image_json && EventData.image_json.length > 0) ?
                <>
                    <section id="hero" className="d-flex align-items-center banner_slider" style={{ paddingBottom: 'calc(680 / 1920 * 100%)', height: 'auto'/* , marginBottom: '30px' */ }}>
                        <Slider className="carousel owl-carousel owl-theme banner-carousel w-100" {...options} infinite={(EventData.image_json.length > 1) ? true : false} style={{ position: 'absolute', top: '0', left: '0' }} >
                            {EventData.image_json.map((Slide: any, i: any) => {
                                var BannerImage = `${process.env.PUBLIC_URL}/img/placeholder.png`
                                if (window.innerWidth < 768) {
                                    if (Slide.s_imageurl !== undefined && Slide.s_imageurl !== '') {
                                        BannerImage = Slide.s_imageurl
                                    }
                                } else {
                                    if (Slide.imageurl !== undefined && Slide.imageurl !== '') {
                                        BannerImage = Slide.imageurl
                                    }
                                }

                                var BannerTitle = {} as any
                                if (Slide.verticalAlign !== undefined) {
                                    if (Slide.verticalAlign === 'top') {
                                        BannerTitle['top'] = '20px';
                                    } else if (Slide.verticalAlign === 'center') {
                                        BannerTitle['top'] = '50%';
                                        BannerTitle['transform'] = 'translateY(-50%)';
                                        /* left: 50%;
                                        transform: translate(-50%, 0%); */
                                    } else if (Slide.verticalAlign === 'bottom') {
                                        BannerTitle['bottom'] = (window.innerWidth < 575) ? '30px' : '40px';
                                    } else {
                                        BannerTitle['top'] = '20px';
                                    }
                                } else {
                                    BannerTitle['bottom'] = (window.innerWidth < 575) ? '30px' : '40px';
                                }
                                if (Slide.horizontalAlign !== undefined) {
                                    if (Slide.horizontalAlign === 'left') {
                                        BannerTitle['left'] = '10px';
                                    } else if (Slide.horizontalAlign === 'center') {
                                        BannerTitle['left'] = '50%';
                                        BannerTitle['transform'] = 'translateX(-50%)';
                                        /* left: 50%;
                                        transform: translate(-50%, 0%); */
                                    } else if (Slide.horizontalAlign === 'right') {
                                        BannerTitle['right'] = '10px';
                                    } else {
                                        BannerTitle['left'] = '10px';
                                    }
                                } else {
                                    BannerTitle['left'] = '10px';
                                }
                                var Ia = BannerImage.split('.')
                                var ImageType = Ia[Ia.length - 1]
                                var isImage = (ImageType === 'mp4') ? 'video' : 'image'
                                return (<div className="banner-items" data-nav-text={(Slide.s_i_alt !== undefined && Slide.s_i_alt !== 'undefined') ? Slide.s_i_alt : ''} key={`${i}-bannerSlider`}>
                                    {Slide.s_l_url !== undefined && Slide.s_l_url !== '' && Slide.s_l_url !== 'undefined' ?
                                        <Link to={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: '9' }} title={Slide.s_title}>
                                            {isImage === 'image' ?
                                                <img src={`${BannerImage}`} alt={(Slide.s_i_alt !== undefined && Slide.s_i_alt !== 'undefined') ? Slide.s_i_alt : ''} title={(Slide.s_i_alt !== undefined && Slide.s_i_alt !== 'undefined') ? Slide.s_i_alt : ''} />
                                                : <video src={`${BannerImage}`} autoPlay={true} loop={true} style={{ width: '100%', height: '100%', objectFit: 'cover' }}></video>}
                                        </Link> :
                                        <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: '9' }}>{isImage === 'image' ?
                                            <img src={`${BannerImage}`} alt={(Slide.s_i_alt !== undefined && Slide.s_i_alt !== 'undefined') ? Slide.s_i_alt : ''} title={(Slide.s_i_alt !== undefined && Slide.s_i_alt !== 'undefined') ? Slide.s_i_alt : ''} />
                                            : <video src={`${BannerImage}`} autoPlay={true} loop={true} muted={true} style={{ width: '100%', height: '100%', objectFit: 'cover' }}></video>}
                                        </div>
                                    }
                                    <div className="banner-text-2" style={BannerTitle}>
                                        {Slide.s_header && Slide.s_header !== 'undefined' && Slide.s_header !== undefined ? <div className="banner-sub-title" dangerouslySetInnerHTML={{
                                            __html: Slide.s_header.replace(['\r\n'],['<br/>'])
                                        }}></div> : ''}
                                        {Slide.s_title && Slide.s_title !== 'undefined' && Slide.s_title !== undefined ? <h2 className="banner-title">{Slide.s_title}</h2> : ''}
                                    </div>
                                </div>)
                            })}
                        </Slider>
                    </section>
                </>
                : ''}
        </>
    )
}

export default BannerSlider