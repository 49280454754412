import React from "react";
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SayingsImageAndText } from './CommonSections'
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
import { SectionTitle } from "./CommonSections";
/* import { Link } from "react-router-dom"; */
const options = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 1000,
        settings: {
            slidesToShow: 3
        }
    }, , {
        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]

};

const Sayings = ({ Data }: any) => {
    let Classes = ''
    let ClassesCarousel = ''
    if (Data.title.trim() === '' || Data.title === undefined || Data.title === 'undefined' || Data.tSlider === 'image_gallery') {
        Classes = 'p-0'
        ClassesCarousel = 'mt-4'
    }
    return (
        <>
            {(Data.image_json && Data.image_json.length > 0) ?
                <>
                    <section className={`sayings ${Classes}`}>
                        <div className="container " >
                            <div className="row">
                                <SectionTitle title={Data.title} type={`${Data.tSlider === 'image_gallery' ? `title-active-color` : ``}`} />
                                <ScrollAnimation className="col-lg-12 pt-0 pt-lg-0 d-flex flex-column justify-content-center" animateIn="fadeIn" animateOnce={true} delay={100} offset={0} >
                                    {Data.dStyle !== undefined && Data.dStyle !== 'undefined' && Data.dStyle === 'thumb' ? <div className={`they-say-carousel row ${ClassesCarousel}`}>
                                        {Data.image_json.map((Slide: any, i: any) => {
                                            return (<div className="col-12 col-md-12 col-lg-3 mt-3 mt-md-3 mt-0"><SayingsImageAndText Image={`${Slide.imageurl}`} Text={Slide.s_i_alt} URL={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} /></div>)
                                        })}
                                    </div> : <Slider className={`they-say-carousel owl-nav-en ${ClassesCarousel}`} {...options}>
                                        {Data.image_json.map((Slide: any, i: any) => {
                                            return (<SayingsImageAndText Image={`${Slide.imageurl}`} Text={Slide.s_i_alt} URL={`${process.env.PUBLIC_URL}${Slide.s_l_url}`} />)
                                        })}
                                    </Slider>}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </section>
                </> : ''}
        </>
    )
}

export default Sayings