import React, { useContext, useEffect, useRef, useState } from "react";
/* import Banner from './sections/Banner' */
import { useNavigate, useParams, Link, /* useLocation, */ } from 'react-router-dom'
import GlobalContext from './Core/PageData'
//import ReactPaginate from 'react-paginate'
import axios from 'axios'
import BreadCrumb from "./sections/BreadCrumb";
import { reduxConnect } from "../redux/store";
/* import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; */
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/* import CacheManager from "../service/CacheManager"; */
import { PrintDesc, PageLoader, LazyImage, /* LimitDesc, */ ImageWithText, NoDataFound, TabPanel, MetaData, getKeyword, GetPublicationBookChapterUrl, GetPublicationUrl } from "./sections/CommonSections"
// @ts-ignore
import ScrollAnimation from 'react-animate-on-scroll'
// @ts-ignore
//import { Document, Page, pdfjs } from 'react-pdf';
// @ts-ignore
/* import { LazyLoadImage } from 'react-lazy-load-image-component'; */
import 'react-lazy-load-image-component/src/effects/opacity.css';
// @ts-ignore
import fileDownload from 'js-file-download'
import { InlineShareButtons } from 'sharethis-reactjs';
import { ToastContainer, toast, Slide } from 'react-toastify';
/* import 'react-toastify/dist/ReactToastify.css'; */

const options = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 768,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 0,
        settings: {
            slidesToShow: 1
        }
    }]
};

const Publication: React.FC = (props: any) => {
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const { GlobalPlayer, setGlobalPlayer } = useContext(GlobalContext as any) as any
    let { publicationSlug, publicationSubCategorySlug, publicationLanguageSlug, publicationSingleSlug, SectionSlug, ChapterSlug, trackSlug } = useParams();
    document.title = `Publication Details${GlobalContent.pageTitle}`;
    const myRef = useRef<HTMLDivElement>(null)
    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    const navigate = useNavigate()
    const [isPageLoad, setisPageLoad] = useState('no')
    const [publicationTrackData, setpublicationTrackData] = useState({ data: [] as any[] });
    const [publicationTrackPDFData, setpublicationTrackPDFData] = useState({ data: [] as any[] });
    const [RelatedData, setRelatedData] = useState({ data: [] as any[] });
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [publication, setpublication] = useState({ data: [] as any[] });
    const [publicationSubCategory, setpublicationSubCategory] = useState({ data: [] as any[] });
    const [publicationSingle, setpublicationSingle] = useState({ data: [] as any[] });
    const [publicationSidebarstatus, setpublicationSidebarstatus] = useState('close');
    const [PubSidebar, setPubSidebar] = useState({ data: [] as any[] });
    const [TrackArtist, setTrackArtist] = useState({ data: [] as any[] });
    const [VideoData, setVideoData] = useState({ data: [] as any[] });
    const [ThisUrl, setThisUrl] = useState('')

    const [PublicationSection, setPublicationSection] = useState({ data: [] as any[] });
    const [PublicationChapter, setPublicationChapter] = useState({ data: [] as any[] });
    const [PublicationChapterInfo, setPublicationChapterInfo] = useState({ data: [] as any[] });
    const [Blind, setBlind] = useState(0)
    const [MetaKeyword, setMetaKeyword] = useState('');

    const [TabData, setTabData] = useState({ data: {} as any });
    const [TabDatas, setTabDatas] = useState({ data: [] as any[] });

    const [bookSlugApi, setbookSlugApi] = useState({ data: [] as any[] });
    const [sectionSlugApi, setsectionSlugApi] = useState({ data: [] as any[] });
    const [chapterSlugApi, setchapterSlugApi] = useState({ data: [] as any[] });

    const [AudioTabopen, setAudioTabopen] = useState('')

    const current_path = props.playerList.list[props.playerList?.current];
    const playAll = () => {
        if (publicationTrackData.data.length > 0) {
            /* let old_list = Array.from(props.playerList.list);
            publicationTrackData.data.map((element: any, index: any) => {
                if (!old_list.some((entry: any) => entry.id === element.id)) {
                    old_list.push(element);
                }
            });
            props.setPlayerList({ list: old_list });
            CacheManager.set({ key: "list", value: old_list });
            // CacheManager.set({ key: "current", value: props.playerList.list[0].id });
            if (props.playerList.current === -1) {
                props.setPlayerList({ current: 0 });
            } */
            let track_ = [] as any
            publicationTrackData.data.map((element: any, index: any) => {
                if (!GlobalPlayer.audiolist.some((entry: any) => entry.id === element.id)) {
                    track_.push(element);
                }
            });
            setGlobalPlayer({ ...GlobalPlayer, audiolist: GlobalPlayer.audiolist.concat(track_) })
            if (track_.length > 0) {
                toast.success('All Tracks are added in queue!', {
                    position: "bottom-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide
                });
            } else {
                toast.info('All Tracks are already added in queue!', {
                    position: "bottom-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide
                });
            }
        }
    }
    const AddQueue = (element: any) => {
        /* let old_list = Array.from(props.playerList.list);
        if (!old_list.some((e: any) => e.id === element.id)) {
            old_list.push(element);
            props.setPlayerList({ list: old_list });
            CacheManager.set({ key: "list", value: old_list });
        } else {
            alert("Already added!!!");
        } */
        let track_ = [] as any
        if (!GlobalPlayer.audiolist.some((e: any) => e.id === element.id)) {
            track_.push(element);
            setGlobalPlayer({ ...GlobalPlayer, audiolist: GlobalPlayer.audiolist.concat(track_) })
            toast.success('Track is added in queue!', {
                position: "bottom-center",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Slide
            });
        } else {
            toast.info('Track is already added in queue!', {
                position: "bottom-center",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Slide
            });

        }
    };

    const playTrack = async (Trackelement: any) => {
        const trackPromises = [] as any;

        if (publicationTrackData.data.length > 0) {
            publicationTrackData.data.map((element: any, index: any) => {
                if (!GlobalPlayer.audiolist.some((entry: any) => entry.id === element.id) && element.id === Trackelement.id) {
                    trackPromises.push(element);
                }
            });
        }
        await Promise.all(trackPromises).then(track_ => {
            var Index = GlobalPlayer.audiolist.concat(track_).findIndex((e: any) => e.id === Trackelement.id)
            setGlobalPlayer({ ...GlobalPlayer, playIndex: Index, audiolist: GlobalPlayer.audiolist.concat(track_) })
        })
        var iSIntrack = GlobalPlayer.audiolist
        if (iSIntrack.length > 0) {
            GlobalPlayer.playerInstance.play()
            //navigate(`${publicationSlug}/${publicationSubCategorySlug}/${publicationSingleSlug}/${Trackelement.slug}`)
        } else if (trackSlug !== undefined) {
            GlobalPlayer.playerInstance.play()
        }
    }
    const pauseTrack = (Trackelement: any) => {
        GlobalPlayer.playerInstance.pause()
    }

    const DoDownload = async (url: any, filename: any) => {
        let a = document.createElement('a');
        await fetch(url, { method: "GET", headers: {} }).then(async response => await response.blob()).then(async blob => {
            let blobUrl = await window.URL.createObjectURL(blob);
            a.href = blobUrl;
            a.download = url.replace(/^.*[\\\/]/, '');
            document.body.appendChild(a);
            a.click();
            await window.URL.revokeObjectURL(blobUrl);
            a.remove();
        })
    }
    useEffect(() => {
        (async () => {
            setisPageLoad('no')
            setpublicationSidebarstatus('close')
            setPublicationChapterInfo({ data: [] })
            setPublicationChapter({ data: [] })
            setpublicationSingle({ data: [] })
            setTrackArtist({ data: [] })
            setRelatedData({ data: [] })

            if (publicationSlug === 'audio' || publicationSlug === 'video') {
                axios.get(`${process.env.REACT_APP_API_URL}publication/${publicationSlug}-category/all`).then(json => {
                    json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                    setPubSidebar({ data: json.data.data })
                    return json.data.data
                }).then(async pubSidebar => {
                    const promises = [] as any;
                    await pubSidebar.forEach((data: any, j: any) => {
                        const promise = axios.get(`${process.env.REACT_APP_API_URL}publication/${publicationSlug}-album/category-wise/${data._id}`).then(json => {
                            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                            pubSidebar[j]['child'] = json.data.data
                            return pubSidebar[j]
                        })
                        promises.push(promise);
                    })
                    Promise.all(promises).then(dsa => {
                        setPubSidebar({ data: dsa })
                    })
                })
            }

            axios.get(`${process.env.REACT_APP_API_URL}publication/type/slug/${publicationSlug}`).then(async json => {
                if(json.data.data.length === 0){
                    navigate(`${process.env.PUBLIC_URL}/404`)
                }
                setpublication({ data: json.data.data })
                if (json.data.data.length > 0 && json.data.data[0].metaKeyword !== undefined && json.data.data[0].metaKeyword !== '') {
                    setMetaKeyword(await getKeyword(json.data.data[0].metaKeyword) as any)
                }
                return json.data.data
            }).then(async publication_temp => {

                let pubSubContent = [] as any
                let pubSingleContent = [] as any
                let PublicationSection = [] as any
                let PublicationChapter = [] as any
                let TrackLyrics = [] as any
                let PubSubUrl = '';
                let PubSubSingleUrl = '';
                if (publication_temp[0]['name'] === 'Audio') {
                    PubSubUrl = 'publication/audio-category/slug'
                    PubSubSingleUrl = 'publication/audio-album/slug'
                } else if (publication_temp[0]['name'] === 'Video') {
                    PubSubUrl = 'publication/video-category/slug'
                    PubSubSingleUrl = 'publication/video-album/slug'
                } else if (publication_temp[0]['name'] === 'Books') {
                    PubSubUrl = 'publication/book-category/slug'
                    //PubSubSingleUrl = 'publication/book/slug'
                    PubSubSingleUrl = `publication/book/${publicationSubCategorySlug}/${publicationLanguageSlug}`
                } else if (publication_temp[0]['name'] === 'Shree Ghanshyam Vijay') {
                    PubSubUrl = 'publication/gvijay-category/slug'
                    PubSubSingleUrl = 'publication/gvijay/slug'
                }

                pubSubContent = await axios.get(`${process.env.REACT_APP_API_URL}${PubSubUrl}/${publicationSubCategorySlug}`).then(json => {
                    if(json.data.data.length === 0){
                        navigate(`${process.env.PUBLIC_URL}/404`)
                    }
                    setpublicationSubCategory({ data: json.data.data })
                    return json.data.data
                })

                if (publicationSingleSlug !== undefined) {
                    pubSingleContent = await axios.get(`${process.env.REACT_APP_API_URL}${PubSubSingleUrl}/${publicationSingleSlug}`).then(async json => {
                        if (json.data.data.length === 0) {
                            navigate(`${process.env.PUBLIC_URL}/404`)
                        }
                        setpublicationSingle({ data: json.data.data })
                        setGlobalContent({ ...GlobalContent, siteData: sites[0], headerClass: '' })
                        if (json.data.data[0] !== undefined && json.data.data[0].artist !== undefined && json.data.data[0].artist.length > 0) {

                            json.data.data[0].artist.map((element: any, j: any) => {
                                if (element.artist_id !== '' && element.artist_type_id !== '' && element.artist_id !== 'undefined' && element.artist_type_id !== 'undefined' && element.artist_id !== undefined && element.artist_type_id !== undefined) {
                                    let ll = element.artist_id.split(',')
                                    if (ll.length > 0) {
                                        var artistName = [] as any
                                        var artistId = [] as any
                                        ll.map((data: any, i: any) => {
                                            var ArtistName__ = '';
                                            json.data.data[0].artistName.map((ele: any, index: any) => {
                                                if (ele[data] !== undefined) {
                                                    ArtistName__ = ele[data]
                                                }
                                            })
                                            artistName.push(ArtistName__)
                                            artistId.push(data)
                                            /* if (json.data.data[0].artistName[j][data] !== undefined) {
                                                artistName.push(json.data.data[0].artistName[j][data])
                                                artistId.push(data)
                                            } */
                                        })
                                        if (artistName.length > 0) {
                                            TrackLyrics.push({ ArtistType: json.data.data[0].artistTypeName[j][element.artist_type_id], artistId: artistId.join(','), ArtistName: artistName.join(', ') })
                                        }
                                    }
                                }
                            })
                        }
                        setTrackArtist({ data: TrackLyrics })
                        /* if (TrackLyrics.find((el: any) => el.ArtistType === 'Author') !== undefined) {
                            var AuthorId = TrackLyrics.find((el: any) => el.ArtistType === 'Author').artistId
                            let RealtedDataPAudio = await axios.get(`${process.env.REACT_APP_API_URL}publication/audio-album/front?artist=${AuthorId}${(publication_temp[0]['name'] === 'Audio') ? `&category=${json.data.data[0].category}` : ''}`).then(json => {
                                return {
                                    'title': 'Related Audio Album',
                                    'slug': 'audio',
                                    'related_data': json.data.data.data
                                }
                            })
                            let RealtedDataPBooks = await axios.get(`${process.env.REACT_APP_API_URL}publication/book/front?artist=${AuthorId}${(publication_temp[0]['name'] === 'Books') ? `&category=${json.data.data[0].category}` : ''}`).then(json => {
                                return {
                                    'title': 'Related Books',
                                    'slug': 'books',
                                    'related_data': json.data.data.data
                                }
                            })
                            let RealtedDataPVideo = await axios.get(`${process.env.REACT_APP_API_URL}publication/video-album/front?artist=${AuthorId}${(publication_temp[0]['name'] === 'Video') ? `&category=${json.data.data[0].category}` : ''}`).then(json => {
                                return {
                                    'title': 'Related Video',
                                    'slug': 'video',
                                    'related_data': json.data.data.data
                                }
                            })
                            setRelatedData({ data: [RealtedDataPAudio, RealtedDataPBooks, RealtedDataPVideo] })
                        } */
                        return json.data.data
                    })
                } else {
                    pubSingleContent = [];
                }

                if (SectionSlug !== undefined) {
                    PublicationSection = await axios.get(`${process.env.REACT_APP_API_URL}publication/book-section/slug/${SectionSlug}`).then(json => {
                        setPublicationSection({ data: json.data.data })
                        return json.data.data
                    })
                } else {
                    PublicationSection = []
                }

                if (ChapterSlug !== undefined && SectionSlug !== undefined) {
                    var PubSingleSec = pubSingleContent[0].section_id.split(',')
                    var PublicationSectionID = ''
                    if (PublicationSection.length > 0) {
                        PublicationSection.map((e: any) => {
                            if (PubSingleSec.includes(e._id)) {
                                PublicationSectionID = e._id
                            }
                        })
                    }
                    PublicationChapter = await axios.get(`${process.env.REACT_APP_API_URL}publication/book-chapter/slug/${pubSingleContent[0]['_id']}/${(PublicationSectionID !== '') ? PublicationSectionID : PublicationSection[0]['_id']}/${ChapterSlug}`).then(json => {
                        setPublicationChapter({ data: json.data.data })
                        return json.data.data
                    })
                } else {
                    PublicationChapter = []
                }

                return { pubSubContent: pubSubContent as any, pubmain: publication_temp, pubsingle: pubSingleContent as any, PublicationSection: PublicationSection as any, PublicationChapter: PublicationChapter as any, TrackLyrics: TrackLyrics }
            }).then(async publicationSubCategory_temp => {

                if (publicationSubCategory_temp.pubsingle.length > 0) {
                    var AuthorId = ''
                    var RealtedDataPAudio = {}
                    if (publicationSubCategory_temp.TrackLyrics.find((el: any) => el.ArtistType === 'Author') !== undefined) {
                        AuthorId = publicationSubCategory_temp.TrackLyrics.find((el: any) => el.ArtistType === 'Author').artistId
                    }

                    /* RealtedDataPAudio = await axios.get(`${process.env.REACT_APP_API_URL}publication/audio-album/front?artist=${AuthorId}${(publicationSubCategory_temp.pubmain[0]['name'] === 'Audio') ? `&category=${publicationSubCategory_temp.pubsingle[0].category}` : ''}`).then(json => {
                        return {
                            'title': 'Related Audio Album',
                            'slug': 'audio',
                            'related_data': json.data.data.data
                        }
                    }) */
                    if (AuthorId !== '') {
                        let RealtedDataPBooks = await axios.get(`${process.env.REACT_APP_API_URL}publication/book/front?artist=${AuthorId}${(publicationSubCategory_temp.pubmain[0]['name'] === 'Books') ? `&category=${publicationSubCategory_temp.pubsingle[0].category}` : ''}`).then(json => {
                            return {
                                'title': 'Related Books',
                                'slug': 'books',
                                'related_data': json.data.data.data
                            }
                        })
                        let RealtedDataPVideo = await axios.get(`${process.env.REACT_APP_API_URL}publication/video-album/front?artist=${AuthorId}${(publicationSubCategory_temp.pubmain[0]['name'] === 'Video') ? `&category=${publicationSubCategory_temp.pubsingle[0].category}` : ''}`).then(json => {
                            return {
                                'title': 'Related Video',
                                'slug': 'video',
                                'related_data': json.data.data.data
                            }
                        })
                        setRelatedData({ data: /* [RealtedDataPAudio, RealtedDataPBooks, RealtedDataPVideo], */ RelatedData.data.concat([RealtedDataPBooks, RealtedDataPVideo]) })
                    }

                    if (publicationSubCategory_temp.pubmain[0]['name'] === 'Audio') {

                        var BookSection = await axios.get(`${process.env.REACT_APP_API_URL}publication/book-section/all`).then(json => {
                            return json.data.data
                        })

                        await axios.get(`${process.env.REACT_APP_API_URL}publication/audio-album/audio/${publicationSubCategory_temp.pubsingle[0]['_id']}`).then(async json => {
                            const trackSinglePromise = { pdf: [], track: [], tabLang: [], bookSlugApi1: [] } as any;
                            const trackSinglePDFPromise = [] as any
                            json.data.data.forEach(async (trackSingle: any, j: any) => {
                                if (trackSingle.audio_file !== undefined && trackSingle.audio_file !== '') {
                                    let TrackLyricsSingle = [] as any
                                    var SubCatTemp = publicationSubCategory_temp.TrackLyrics.find((e: any) => e.ArtistType === 'Singer')
                                    if (SubCatTemp !== undefined) {
                                        var ArtistNameTemp = publicationSubCategory_temp.TrackLyrics.find((e: any) => e.ArtistType === 'Singer').ArtistName
                                    } else {
                                        var ArtistNameTemp = '' as any
                                    }
                                    var SingerSingle = [/* ArtistNameTemp */] as any
                                    var sectionName = ''
                                    sectionName = BookSection.filter((e: any) => e._id === trackSingle.language[0].section_id)[0]?.title

                                    if (trackSingle.artist !== undefined && trackSingle.artist.length > 0) {
                                        trackSingle.artist.map((element: any, j: any) => {
                                            let ll = element.artist_id.split(',')
                                            if (ll.length > 0) {
                                                var artistName = [] as any
                                                ll.map((data: any, i: any) => {
                                                    /* artistName.push(trackSingle.artistName[j][data]) */
                                                    trackSingle.artistName.map((ele: any, index: any) => {
                                                        if (ele[data] !== undefined) {
                                                            artistName.push(ele[data])
                                                        }
                                                    })
                                                })

                                                if (trackSingle.artistTypeName[j][element.artist_type_id] === 'Singer') {
                                                    SingerSingle = SingerSingle.concat(artistName)
                                                }
                                                TrackLyricsSingle.push({ ArtistType: trackSingle.artistTypeName[j][element.artist_type_id], ArtistName: artistName.join(', ') })
                                            }
                                        })
                                    }

                                    //setTrackArtist({ data: TrackLyrics })
                                    trackSinglePromise.track.push({
                                        /* id: trackSingle._id,
                                        name: trackSingle.title,
                                        author_name: "Jeevanpran Shree Muktajeevan Swamibapa",
                                        img: publicationSubCategory_temp.pubsingle[0].banner_image,
                                        lang: "ENGLISH",
                                        timesPlayed: 0,
                                        duration: "3:22",
                                        type: "1",
                                        musicName: trackSingle.audio_file,
                                        pdf: dsa */
                                        id: trackSingle._id,
                                        name: `${(sectionName !== undefined && sectionName !== 'undefined' && sectionName !== '' && sectionName.toLowerCase() != trackSingle.title.toLowerCase() && sectionName.toLowerCase() !== 'all') ? `${sectionName} - ` : ''}${trackSingle.title}`,
                                        singer: Array.from(new Set(SingerSingle)).filter(Boolean).join(', '),
                                        cover: publicationSubCategory_temp.pubsingle[0].banner_image,
                                        musicSrc: trackSingle.audio_file,
                                        slug: trackSingle.slug,
                                        language: trackSingle.language
                                    })
                                }
                            })

                            Promise.all([trackSinglePromise]).then(async dsa => {
                                if (dsa[0].track.length > 0) {
                                    setpublicationTrackData({ data: dsa[0].track })
                                    axios.get(`${process.env.REACT_APP_API_URL}publication/related_audio/${dsa[0].track[0].id}`).then(json => {
                                        return /* json.data.data */[];
                                    }).then((RelatedDataTemp: any) => {
                                        //dataR.categorySlug[0][dataR.category]}/${dataR.slug
                                        let VideoDataTemp = [] as any
                                        RelatedDataTemp.forEach((ContentData: any, i: any) => {
                                            axios.get(`${process.env.REACT_APP_API_URL}publication/audio-album/${ContentData.album}`).then(json => {
                                                RelatedDataTemp[i]['categorySlug'] = json.data.data[0]['categorySlug']
                                                RelatedDataTemp[i]['category'] = json.data.data[0]['category']
                                                RelatedDataTemp[i]['albumSlug'] = json.data.data[0]['slug']
                                            })
                                        })
                                        /* setRelatedData({
                                            data: [...RelatedData.data, {
                                                'title': 'Related Audio Album',
                                                'slug': 'audio',
                                                'related_data': RelatedDataTemp
                                            }]
                                        }) */
                                        RealtedDataPAudio = {
                                            'title': 'Related Audio Album',
                                            'slug': 'audio',
                                            'related_data': RelatedDataTemp
                                        }
                                        /* setRelatedData({ data: RelatedData.data.concat([RealtedDataPAudio]) }) */
                                    }).catch((err) => {
                                        console.log(err.message);
                                    });
                                }
                            })
                        })
                    } else if (publicationSubCategory_temp.pubmain[0]['name'] === 'Video') {
                        axios.get(`${process.env.REACT_APP_API_URL}publication/video-album/video/${publicationSubCategory_temp.pubsingle[0]['_id']}`).then(async json => {
                            let VideoDataTemp = [] as any
                            json.data.data.forEach((ContentData: any, i: any) => {
                                if (ContentData.video_file !== '' && ContentData.video_file !== undefined && ContentData.video_file !== 'undefined') {
                                    VideoDataTemp.push({
                                        image: (ContentData.banner_image !== '' && ContentData.banner_image !== undefined) ? ContentData.banner_image : ``,
                                        text: ContentData.title,
                                        URL: ContentData.video_file,
                                        type: 'LocalVideo'
                                    })
                                } else {
                                    VideoDataTemp.push({
                                        image: (ContentData.banner_image !== '' && ContentData.banner_image !== undefined) ? ContentData.banner_image : `https://img.youtube.com/vi/${ContentData.video_url}/hqdefault.jpg`,
                                        text: ContentData.title,
                                        URL: `https://www.youtube.com/watch?v=${ContentData.video_url}`,
                                        type: 'video'
                                    })
                                }
                            })
                            setVideoData({ data: VideoDataTemp })
                        })
                    }

                    if (ChapterSlug !== undefined) {
                        if (publicationSubCategory_temp.PublicationChapter.length > 0) {
                            await axios.get(`${process.env.REACT_APP_API_URL}publication/book-chapter-info/${publicationSubCategory_temp.pubsingle[0]['_id']}/${publicationSubCategory_temp.PublicationChapter[0]['_id']}/`).then(json => {
                                setPublicationChapterInfo({ data: json.data.data })
                                return json.data.data
                            })
                        }
                    }

                    if (publicationSubCategory_temp.pubmain[0]['name'] === 'Books') {
                        if (publicationSubCategory_temp.pubsingle[0]['section_id'] !== '') {
                            axios.get(`${process.env.REACT_APP_API_URL}publication/book-section/ids/${publicationSubCategory_temp.pubsingle[0]['section_id']}`).then(async json => {
                                json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                                setPubSidebar({ data: json.data.data })
                                return json.data.data
                            }).then(async pubSidebar => {
                                pubSidebar.sort((a: any, b: any) => (a.displayOrderByBook > b.displayOrderByBook) ? 1 : -1)
                                const promises = [] as any;
                                await pubSidebar.forEach((data: any, j: any) => {
                                    const promise = axios.get(`${process.env.REACT_APP_API_URL}publication/book-chapter/level1/${data._id}`).then(json => {
                                        json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                                        pubSidebar[j]['child'] = json.data.data
                                        return pubSidebar[j]
                                    })
                                    promises.push(promise);
                                })
                                Promise.all(promises).then(dsa => {
                                    setPubSidebar({ data: dsa })
                                })
                            })
                        }
                    }

                    let BreadCrumbdata_ = [
                        {
                            path: `${process.env.PUBLIC_URL}/`,
                            linkName: 'Home',
                            status: '',
                        }, {
                            path: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}`,
                            linkName: 'Publications',
                            status: '',
                        }]
                    BreadCrumbdata_.push({
                        path: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${publicationSubCategory_temp.pubmain[0]['slug']}`,
                        linkName: publicationSubCategory_temp.pubmain[0]['name'],
                        status: '',
                    })
                    BreadCrumbdata_.push({
                        path: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${publicationSubCategory_temp.pubmain[0]['slug']}/${publicationSubCategory_temp.pubSubContent[0]['slug']}`,
                        linkName: publicationSubCategory_temp.pubSubContent[0]['name'],
                        status: '',
                    })
                    if (publicationSubCategory_temp.pubsingle.length > 0) {
                        BreadCrumbdata_.push({
                            path: GetPublicationUrl(publicationSubCategory_temp.pubsingle[0], publicationSubCategory_temp.pubmain[0]['slug']),
                            linkName: publicationSubCategory_temp.pubsingle[0]['title'],
                            status: '',
                        })
                    }
                    if (publicationSubCategory_temp.PublicationSection.length > 0) {
                        BreadCrumbdata_.push({
                            path: GetPublicationUrl(publicationSubCategory_temp.pubsingle[0], publicationSubCategory_temp.pubmain[0]['slug']),
                            linkName: publicationSubCategory_temp.PublicationSection[0]['title'],
                            status: '',
                        })
                    }
                    if (publicationSubCategory_temp.PublicationChapter.length > 0) {
                        BreadCrumbdata_.push({
                            path: GetPublicationUrl(publicationSubCategory_temp.pubsingle[0], publicationSubCategory_temp.pubmain[0]['slug']),
                            linkName: publicationSubCategory_temp.PublicationChapter[0]['title'],
                            status: '',
                        })
                    }
                    setThisUrl(`${process.env.REACT_APP_SITE_URL}${BreadCrumbdata_[BreadCrumbdata_.length - 1].path}`)
                    setBreadCrumbdata({ data: BreadCrumbdata_ })
                    document.title = `${(publicationSubCategory_temp.pubsingle.length > 0) ? publicationSubCategory_temp.pubsingle[0]['title'] : ''}${GlobalContent.pageTitle}`;
                    setisPageLoad('yes')
                }

            })

        })();
    }, [publicationSingleSlug, SectionSlug, ChapterSlug, trackSlug]);

    /* useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}publication/related_audio/${publicationTrackData.data[0].id}`).then(json => {
            return json.data.data;
        }).then(RelatedDataTemp => {
            //dataR.categorySlug[0][dataR.category]}/${dataR.slug
            let VideoDataTemp = [] as any
            RelatedDataTemp.forEach((ContentData: any, i: any) => {
                axios.get(`${process.env.REACT_APP_API_URL}publication/audio-album/${ContentData.album}`).then(json => {
                    RelatedDataTemp[i]['categorySlug'] = json.data.data[0]['categorySlug']
                    RelatedDataTemp[i]['category'] = json.data.data[0]['category']
                    RelatedDataTemp[i]['albumSlug'] = json.data.data[0]['slug']
                })
            })
            setRelatedData({
                data: [...RelatedData.data, {
                    'title': 'Related Audio Album',
                    'slug': 'audio',
                    'related_data': RelatedDataTemp
                }]
            })
        })
    }, [publicationTrackData]) */

    const OpenSidebar = () => {
        setpublicationSidebarstatus('open')
    }
    const CloseSidebar = () => {
        setpublicationSidebarstatus('close')
    }

    useEffect(() => {
        if (GlobalPlayer.currentPlayAudioId !== '') {
            var lang = publicationTrackData.data.filter((e: any) => e.id === GlobalPlayer.currentPlayAudioId)
            if (lang.length > 0) {
                ShowAudioTab(lang[0].language)
            }
        }
    }, [GlobalPlayer.currentPlayAudioId]);

    const ShowAudioTab = (language: any) => {

        setTabData({ data: { tab_json: [] } })
        var BookSlugUrl = [] as any
        /* json.data.data.forEach(async (trackSingle: any, j: any) => {
            if (trackSingle.audio_file !== undefined && trackSingle.audio_file !== '') { */
        language.forEach(async (trackPdf: any, k: any) => {
            if (trackPdf.book_id !== undefined && trackPdf.book_id !== 'undefined' && trackPdf.book_id !== '' && trackPdf.section_id !== '' && trackPdf.section_id !== undefined && trackPdf.section_id !== 'undefined' && trackPdf.chapter_id !== '' && trackPdf.chapter_id !== undefined && trackPdf.chapter_id !== 'undefined') {
                BookSlugUrl.push(`${process.env.REACT_APP_API_URL}publication/book/${trackPdf.book_id}`)
                BookSlugUrl.push(`${process.env.REACT_APP_API_URL}publication/book-section/${trackPdf.section_id}`)
                BookSlugUrl.push(`${process.env.REACT_APP_API_URL}publication/book-chapter/${trackPdf.chapter_id}`)
                BookSlugUrl.push(`${process.env.REACT_APP_API_URL}publication/book-chapter-info/${trackPdf.book_id}/${trackPdf.chapter_id}/`)
            }
        })
        /* }
    }) */
        BookSlugUrl = [...new Set(BookSlugUrl)];
        var AudioApis = [] as any
        Promise.all(BookSlugUrl.map((endpoint: any) => axios.get(endpoint))).then((json) => {
            json.forEach(async (dd: any, k: any) => {
                AudioApis = AudioApis.concat(dd.data.data)
            })
        }).then(() => {
            var uiuiq = [] as any
            language.forEach(async (trackPdf: any, k: any) => {
                var lang = GlobalContent.AllLanguage.filter((e: any) => e._id === trackPdf.language_id)
                if (trackPdf.audio_lyrics !== '' && trackPdf.audio_lyrics !== undefined && trackPdf.audio_lyrics !== 'undefined') {
                    uiuiq.push({ tabName: lang[0]['name'], desc: `<div class="p-3">${trackPdf.audio_lyrics}</div>`, cmsPageId: 'id' })
                } else {
                    var FromApiChapterInfoSlug = [] as any
                    FromApiChapterInfoSlug = AudioApis.filter((e: any) => e.book_id === trackPdf.book_id && e.chapter_id === trackPdf.chapter_id)
                    if (FromApiChapterInfoSlug.length > 0) {
                        if (FromApiChapterInfoSlug[0].lyrics !== '' && FromApiChapterInfoSlug[0].lyrics !== undefined && FromApiChapterInfoSlug[0].lyrics !== 'undefined') {
                            uiuiq.push({ tabName: lang[0]['name'], desc: `<div class="p-3">${FromApiChapterInfoSlug[0].lyrics}</div>`, cmsPageId: 'id' })
                        } else {
                            if (FromApiChapterInfoSlug[0].chapter_url !== '' && FromApiChapterInfoSlug[0].chapter_url !== undefined && FromApiChapterInfoSlug[0].chapter_url !== 'undefined') {
                                uiuiq.push({ tabName: lang[0]['name'], desc: `<iframe class="" style="width: 100%; height: 80vh" src="${process.env.REACT_APP_SITE_URL}pdfjs/web/viewer.html?file=${FromApiChapterInfoSlug[0].chapter_url}"></iframe>`, cmsPageId: 'id' })
                            } /* else if (FromApiChapterInfoSlug[0].book_fileurl !== '' && FromApiChapterInfoSlug[0].book_fileurl !== undefined && FromApiChapterInfoSlug[0].book_fileurl !== 'undefined') {
                                uiuiq.push({ tabName: lang[0]['name'], desc: `<iframe class="" style="width: 100%; height: 80vh" src="${process.env.REACT_APP_SITE_URL}pdfjs/web/viewer.html?file=${FromApiChapterInfoSlug[0].book_fileurl}"></iframe>`, cmsPageId : 'id' })
                            } */
                        }
                    }
                }
                if (trackPdf.book_id !== '' && trackPdf.section_id !== '' && trackPdf.chapter_id !== '') {
                    var bookSlug = '' as any
                    var sectionSlug = '' as any
                    var chapterSlug = '' as any

                    var FromApibookSlug = [] as any
                    var FromApisectionSlug = [] as any
                    var FromApichapterSlug = [] as any

                    FromApibookSlug = AudioApis.filter((e: any) => e._id === trackPdf.book_id)
                    if (FromApibookSlug.length > 0) {
                        bookSlug = FromApibookSlug[0].categorySlug[0][FromApibookSlug[0].category] + '/' + FromApibookSlug[0].slug
                    }

                    FromApisectionSlug = AudioApis.filter((e: any) => e._id === trackPdf.section_id)
                    if (FromApisectionSlug.length > 0) {
                        sectionSlug = FromApisectionSlug[0].slug
                    }

                    FromApichapterSlug = AudioApis.filter((e: any) => e._id === trackPdf.chapter_id)
                    if (FromApichapterSlug.length > 0) {
                        chapterSlug = FromApichapterSlug[0].slug
                    }

                    /* if (bookSlug !== '') {
                        trackSinglePromise.pdf.push({ index: j, languageId: trackPdf.language_id, pdfSlug: `${bookSlug}/${sectionSlug}/${chapterSlug}` })
                    }
                    setpublicationTrackPDFData({ data: trackSinglePromise.pdf })
                    return `${bookSlug}/${sectionSlug}/${chapterSlug}` */
                }
            })
            setTabData({ data: { tab_json: uiuiq } })
            /* trackSinglePromise.tabLang.push({ /* title: trackSingle.title, tab_json: uiuiq })
            Promise.all([trackSinglePromise]).then(async dsa => {
                setTabDatas({ data: dsa[0].tabLang })
            }) */
        })
    }
    let PDFURL = ''
    let SecChapTitle = ''
    return (
        <>
            {isPageLoad === 'yes' ? <>
                {/* {(() => {
                    var Trackel = publicationTrackData.data.find((el: any) => el.slug === trackSlug)
                    if (Trackel !== undefined) {
                        if (trackSlug === Trackel.slug) {
                            playTrack(Trackel)
                        }
                    }
                })()} */}
                <ToastContainer />
                {(publicationSingle.data.length > 0) ? <>

                    <MetaData keywords={MetaKeyword} description={publicationSingle.data[0].metaDescription} />
                    <style>
                        {`html {overflow: ${(publicationSidebarstatus === 'close') ? `auto` : `hidden`}; padding-right : ${(publicationSidebarstatus === 'close') ? `0px` : `17px`}} `}
                        {/* #header {margin-right : ${(publicationSidebarstatus === 'close') ? `0px` : `17px`}} */}
                    </style>
                    <div className={`publication-details-sidebar-contain ${(publicationSidebarstatus === 'close') ? `inactive` : `active`}`}>
                        {/* <CSSTransition
                            key={currentKey}
                            timeout={timeout}
                            classNames="fade"
                            appear
                        > */}
                        <div className="publication-details-overlay" onClick={CloseSidebar}></div>
                        <div className="publication-details-sidebar">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="title-2 publication-details-sidebar-title w-100 text-truncate" title={publicationSingle.data[0].title}>{publicationSingle.data[0].title}</div>
                                <div className="d-flex flex-row justify-content-center publication-details-sidebar-close"><i className="icofont-close" onClick={CloseSidebar}></i></div>
                            </div>
                            <div className="d-flex flex-column">
                                {PubSidebar.data.length > 0 && PubSidebar.data.map((element: any, j: any) => {
                                    return (
                                        <>
                                            <div className="publication-details-container mt-3 d-flex flex-column p-3">
                                                <div className="title-2 publication-details-sidebar-title w-100">{(element.name !== undefined) ? element.name : element.title}</div>
                                                {element.child && element.child.length > 0 && element.child.map((elementChild: any, j: any) => {
                                                    if (publication.data[0]['name'] === 'Audio' || publication.data[0]['name'] === 'Video') {
                                                        return (<Link to={GetPublicationUrl(elementChild, publicationSlug)} className={`publication-details-sidebar-link mt-2 mb-0 ms-3 ${(elementChild.slug === publicationSingleSlug) ? 'active' : ''}`} title={elementChild.title}><span className="dot">{/* &#183; */}</span> {elementChild.title}</Link>)
                                                    } else if (publication.data[0]['name'] === 'Books') {
                                                        return (<Link to={GetPublicationBookChapterUrl(publicationSingle.data[0], element.slug, elementChild.slug)} className={`publication-details-sidebar-link mt-2 mb-0 ms-3 ${(element.slug === SectionSlug && elementChild.slug === ChapterSlug) ? 'active' : ''}`} title={elementChild.title}><span className="dot">{/* &#183; */}</span> {elementChild.title}</Link>)
                                                    }
                                                })}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        {/* </CSSTransition> */}
                    </div>
                </> : ''}
                {/* {JSON.stringify(publicationTrackData)} */}
                {(() => {

                    if (ChapterSlug !== undefined && PublicationChapterInfo.data.length > 0) {
                        if (PublicationChapterInfo.data[0].chapter_url !== '') {
                            PDFURL = PublicationChapterInfo.data[0].chapter_url
                        }
                    } else {
                        if (publicationSingle.data[0].book_file !== '') {
                            PDFURL = publicationSingle.data[0].book_file
                        }
                    }

                    if (PublicationSection.data.length > 0) {
                        SecChapTitle += PublicationSection.data[0].title + ' - '
                    }
                    if (PublicationChapter.data.length > 0) {
                        SecChapTitle += PublicationChapter.data[0].title
                    } else {
                        SecChapTitle = ''
                    }
                })()}
                <section className="publication-contain">
                    <div className="container">
                        <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                        {(publicationSingle.data.length > 0) ?
                            <>

                                {(SecChapTitle !== '') ? <div className="d-flex publication-details-container-inner flex-column flex-md-column flex-lg-row mt-4 ms-0 ms-lg-4">
                                    <div className="col-12 d-flex flex-column">
                                        <div className="title-2 w-100 " title={publicationSingle.data[0].title}>{publicationSingle.data[0].title}</div>
                                    </div>
                                </div> : ''}
                                <div className={`${(SecChapTitle === '') ? `d-flex` : `d-none d-lg-flex`}  publication-details-container-inner flex-column flex-md-column flex-lg-row justify-content-end mt-3`}>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 d-flex flex-column">
                                        <div className={`${(SecChapTitle !== '') ? 'title-3' : 'title-2'} w-100`} title={(SecChapTitle !== '') ? SecChapTitle : publicationSingle.data[0].title}>{(SecChapTitle !== '') ? SecChapTitle : publicationSingle.data[0].title}</div>
                                    </div>
                                </div>
                                <ScrollAnimation className="publication-details-container" animateIn="fadeIn" animateOnce={true} delay={100} >
                                    <div className="d-flex publication-details-container-inner flex-column flex-md-column flex-lg-row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex flex-column justify-content-start pe-md-0 pe-lg-5">
                                            <div className="publication-image">
                                                <LazyImage
                                                    loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                    actualSrc={`${publicationSingle.data[0].banner_image}`}
                                                    errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                    width={`100%`}
                                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                                    ContainerClass=""
                                                    title={publicationSingle.data[0].title}
                                                    alt={publicationSingle.data[0].title}
                                                />
                                            </div>
                                            <div className="publication-image-divider"></div>
                                            {/* ${publicationSingle.data[0].banner_image} */}
                                            <div className="d-flex flex-column justify-content-center mt-4 mt-md-4">
                                                {(publication.data[0]['name'] === 'Audio') ? <>
                                                    <div className="mb-4 d-flex d-lg-none justify-content-center">
                                                        <a className="btn btn-4 publication-play-btn" onClick={() => playAll()} style={{ position: 'relative', top: 'auto', right: 'auto' }} title="Play All">
                                                            <svg width="15" height="15" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.907 12.1457L3.5836 0.616302C2.10622 -0.368661 0.107422 0.703279 0.107422 2.47031V25.5009C0.107422 27.297 2.10622 28.3398 3.5836 27.3549L20.907 15.8255C22.2105 14.9854 22.2105 13.0155 20.9068 12.1465L20.907 12.1457Z" fill="white" />
                                                            </svg>
                                                            &nbsp;
                                                            Play All
                                                        </a>
                                                    </div>
                                                </> : ''}
                                                <div className="d-flex flex-row justify-content-center">
                                                    {(publication.data[0]['name'] === 'Audio') ? <>
                                                        <a className="btn btn-5 d-flex flex-row justify-content-center" href={publicationSingle.data[0].zip_file} title="Download Album" /* onClick={() => DoDownload(publicationSingle.data[0].zip_file, '')} */>
                                                            <span className="d-flex flex-column justify-content-center">
                                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="#F94F20" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M23.4796 0.397461H7.9032C5.86116 0.397461 4.16667 1.89025 3.84101 3.84076C1.89025 4.16639 0.397461 5.86084 0.397461 7.9032V23.4802C0.397461 25.7544 2.24655 27.6035 4.52074 27.6035H20.0977C22.1388 27.6035 23.834 26.1107 24.1599 24.1599C26.1103 23.8343 27.6032 22.14 27.6032 20.098V4.52099C27.6032 2.2468 25.7529 0.397716 23.4797 0.397716L23.4796 0.397461ZM22.1589 23.4796C22.1589 24.6195 21.2356 25.5411 20.0976 25.5411H4.52061C3.38093 25.5411 2.4591 24.6191 2.4591 23.4796L2.45885 7.9032C2.45885 6.76448 3.38087 5.84169 4.52036 5.84169H20.0974C21.2358 5.84169 22.1586 6.76499 22.1586 7.9032L22.1589 23.4796ZM25.5416 20.0976C25.5416 20.9753 24.9931 21.722 24.2209 22.0195V7.90288C24.2209 5.62869 22.3709 3.77961 20.0976 3.77961L5.98039 3.77986C6.2781 3.0079 7.02484 2.45916 7.9025 2.45916H23.4795C24.6182 2.45916 25.541 3.38245 25.541 4.52067L25.5413 20.0977L25.5416 20.0976ZM12.3091 8.95741C8.5902 8.95741 5.57501 11.9726 5.57501 15.6915C5.57501 19.4106 8.5902 22.4255 12.3091 22.4255C16.0274 22.4255 19.0431 19.4106 19.0431 15.6915C19.0433 11.9723 16.0282 8.95741 12.3091 8.95741ZM15.519 16.3559L14.2521 17.087C13.6188 17.4527 12.5833 18.0509 11.9495 18.4162L10.6839 19.148C10.0506 19.5125 9.53246 19.2145 9.53246 18.4835V17.0206V14.362V12.8997C9.53246 12.1686 10.0503 11.8702 10.6839 12.2349L11.9495 12.9668C12.5831 13.3315 13.6188 13.9299 14.2521 14.2959L15.519 15.027C16.1523 15.393 16.1523 15.9914 15.519 16.3559Z" />
                                                                </svg>
                                                            </span>
                                                            <span className="d-flex flex-column justify-content-center">&nbsp;Download Album</span>
                                                        </a>&nbsp;&nbsp;
                                                    </> : ''}

                                                    {(publication.data[0]['name'] === 'Books' && publicationSingle.data[0].book_file !== '' && publicationSingle.data[0].book_file !== undefined && publicationSingle.data[0].book_file !== 'undefined') ? <>
                                                        <a className="btn btn-5 d-flex flex-row justify-content-center" href={publicationSingle.data[0].book_file} title="Download" /* onClick={() => DoDownload(publicationSingle.data[0].zip_file, '')} */>
                                                            <span className="d-flex flex-column justify-content-center">
                                                                <svg style={{ width: '28px' }} className="music-icon" width="28" height="16" viewBox="0 0 17 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.0304 11.0726V14.3478C16.0304 14.413 16.0203 14.4736 16.0051 14.5295C15.9345 14.8509 15.6266 15.0932 15.2632 15.0932H0.762216C0.373538 15.0932 0.0504874 14.8183 0.0101582 14.469C5.33615e-08 14.427 0 14.3898 0 14.3432V11.068C0 10.6812 0.343216 10.3691 0.757046 10.3691C0.964042 10.3691 1.15572 10.4483 1.29211 10.5742C1.42833 10.6999 1.51426 10.877 1.51426 11.0681V13.6912H14.5215V11.0681C14.5215 10.6814 14.8647 10.3693 15.2785 10.3693C15.4855 10.3693 15.6772 10.4485 15.8136 10.5744C15.9448 10.7044 16.0306 10.8815 16.0306 11.0725L16.0304 11.0726Z" />
                                                                    <path d="M12.3813 7.79282L8.66141 11.2266C8.65641 11.236 8.64626 11.2406 8.64126 11.2452C8.50504 11.3709 8.32836 11.4502 8.15168 11.4735C8.13653 11.4735 8.12137 11.4781 8.10622 11.4781C8.07591 11.4827 8.0456 11.4827 8.0153 11.4827L7.92954 11.4781C7.91438 11.4781 7.89923 11.4735 7.88407 11.4735C7.70239 11.4501 7.5307 11.3709 7.3945 11.2452C7.3895 11.2406 7.37934 11.2312 7.37435 11.2266L3.65443 7.79282C3.48274 7.63434 3.39697 7.42481 3.39697 7.21513C3.39697 7.00545 3.48273 6.7958 3.65443 6.63744C3.99764 6.32063 4.55783 6.32063 4.90621 6.63744L7.14218 8.70141V1.13955C7.14218 0.692238 7.54085 0.324219 8.02545 0.324219C8.26775 0.324219 8.48975 0.417373 8.65128 0.561869C8.81281 0.710977 8.90874 0.911271 8.90874 1.13955V8.70142L11.1447 6.63745C11.4879 6.32064 12.0481 6.32064 12.3965 6.63745C12.7245 6.95903 12.7245 7.47612 12.3813 7.79294V7.79282Z" />
                                                                </svg>
                                                            </span>
                                                            <span className="d-flex flex-column justify-content-center">&nbsp;Download</span>
                                                        </a>&nbsp;&nbsp;
                                                    </> : ''}
                                                    {/* <a className="d-flex flex-column justify-content-center"><img src={`${process.env.PUBLIC_URL}/icons/facebook-circled.png`} alt="facebook" title="facebook" /></a>&nbsp;
                                                <a className="d-flex flex-column justify-content-center"><img src={`${process.env.PUBLIC_URL}/icons/twitter.png`} alt="twitter" title="twitter" /></a> */}
                                                </div>
                                                {(publication.data[0]['name'] !== 'Shree Ghanshyam Vijay' && PubSidebar.data.length > 0) ?
                                                    <div className="d-flex flex-row justify-content-center mt-3">
                                                        <a className="btn btn-5 d-flex flex-row justify-content-center" onClick={OpenSidebar} title={publication.data[0]['name']}>{publication.data[0]['name'] === 'Audio' || publication.data[0]['name'] === 'Video' ? 'More Album' : 'Chapters'}</a>
                                                    </div> : ''}

                                                <div className="d-flex flex-row justify-content-center mt-3" style={{ zIndex: 0 }}>
                                                    <InlineShareButtons
                                                        config={{
                                                            alignment: 'center',  // alignment of buttons (left, center, right)
                                                            color: 'social',      // set the color of buttons (social, white)
                                                            enabled: true,        // show/hide buttons (true, false)
                                                            font_size: 16,        // font size for the buttons
                                                            labels: 'cta',        // button labels (cta, counts, null)
                                                            language: 'en',       // which language to use (see LANGUAGES)
                                                            networks: [           // which networks to include (see SHARING NETWORKS)
                                                                /* 'whatsapp',
                                                                'linkedin',
                                                                'messenger', */
                                                                'facebook',
                                                                'twitter'
                                                            ],
                                                            padding: 12,          // padding within buttons (INTEGER)
                                                            radius: 4,            // the corner radius on each button (INTEGER)
                                                            show_total: true,
                                                            size: 40,             // the size of each button (INTEGER)

                                                            // OPTIONAL PARAMETERS
                                                            url: ThisUrl, // (defaults to current url)
                                                            image: publicationSingle.data[0].banner_image,  // (defaults to og:image or twitter:image)
                                                            description: 'custom text',       // (defaults to og:description or twitter:description)
                                                            title: publicationSingle.data[0].title,            // (defaults to og:title or twitter:title)
                                                            message: 'custom email text',     // (only for email sharing)
                                                            subject: 'custom email subject',  // (only for email sharing)
                                                            username: 'custom twitter handle' // (only for twitter sharing)
                                                        }}
                                                    />
                                                </div>
                                                <div className="publication-artist d-flex mt-3 mt-md-3 mt-lg-3 flex-wrap pt-0 pt-lg-2">
                                                    {(() => {
                                                        var ArtistType = {} as any;
                                                        var PrintArtist = [] as any;
                                                        TrackArtist.data && TrackArtist.data.length > 0 && TrackArtist.data.map((element: any, j: any) => {
                                                            if (ArtistType[element.ArtistType] === undefined) {
                                                                ArtistType[element.ArtistType] = []
                                                            }
                                                            ArtistType[element.ArtistType].push(element.ArtistName)
                                                        })
                                                        Object.keys(ArtistType).map((element: any, j: any) => {
                                                            PrintArtist.push(<span key={`${j}-TrackArtist`}><b>{element}:</b>&nbsp; {ArtistType[element].join(', ')}</span>)
                                                        })
                                                        return PrintArtist
                                                    })()}

                                                    {/* {TrackArtist.data && TrackArtist.data.length > 0 && TrackArtist.data.map((element: any, j: any) => (
                                                        <span key={`${j}-TrackArtist`}><b>{element.ArtistType}:</b>&nbsp; {element.ArtistName}</span>
                                                    ))} */}
                                                    {(() => {
                                                        if (publicationSingle.data[0].language !== '') {
                                                            let ll = publicationSingle.data[0].language.split(',')
                                                            if (ll.length > 0) {
                                                                return (<span><b>Language:</b>&nbsp;
                                                                    {ll.map((data: any, i: any) => (
                                                                        <span className="comma-after">{publicationSingle.data[0].languageName[i][data]}</span>
                                                                    ))}
                                                                </span>)
                                                            }
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (publicationSingle.data[0].category !== '') {
                                                            let ll = publicationSingle.data[0].category.split(',')
                                                            if (ll.length > 0) {
                                                                return (<span><b>Category:</b>&nbsp;{ll.map((data: any, i: any) => (<span className="comma-after">{publicationSingle.data[0].categoryName[i][data]}</span>))}</span>)
                                                            }
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (publicationSingle.data[0].publishLocation !== '') {
                                                            let ll = publicationSingle.data[0].publishLocation.split(',')
                                                            if (ll.length > 0) {
                                                                return (<span><b>Published:</b>&nbsp;{ll.map((data: any, i: any) => (<span className="comma-after">{publicationSingle.data[0].publishLocationName[i][data]}</span>))}</span>)
                                                            }
                                                        }
                                                    })()}
                                                </div>
                                                {publicationSingle.data[0].desc !== '' ?
                                                    <div className="publication-synopsis mt-3 d-flex flex-column">
                                                        <span className="publication-synopsis-title mb-2">Synopsis</span>
                                                        <p className="publication-synopsis-desc" ><PrintDesc desc={publicationSingle.data[0].desc} isHtml={true} /></p>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 d-flex flex-column ">
                                            {(publication.data[0]['name'] === 'Audio') ?
                                                <a className="btn btn-4 publication-play-btn d-none d-lg-block" onClick={() => playAll()} title="Play All">
                                                    <svg width="15" height="15" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.907 12.1457L3.5836 0.616302C2.10622 -0.368661 0.107422 0.703279 0.107422 2.47031V25.5009C0.107422 27.297 2.10622 28.3398 3.5836 27.3549L20.907 15.8255C22.2105 14.9854 22.2105 13.0155 20.9068 12.1465L20.907 12.1457Z" fill="white" />
                                                    </svg>
                                                    &nbsp;
                                                    Play All
                                                </a>
                                                : ''}
                                            {/* <div className="title-2 publication-details-title w-100 text-truncate" title={publicationSingle.data[0].title}>{publicationSingle.data[0].title}</div> */}
                                            {/* <div className="publication-artist d-flex mt-3 mt-md-3 mt-lg-5 flex-wrap pt-0 pt-lg-2">
                                            {TrackArtist.data && TrackArtist.data.length > 0 && TrackArtist.data.map((element: any, j: any) => (
                                                <span key={`${j}-TrackArtist`}><b>{element.ArtistType}:</b>&nbsp; {element.ArtistName}</span>
                                            ))}
                                            {(() => {
                                                if (publicationSingle.data[0].language !== '') {
                                                    let ll = publicationSingle.data[0].language.split(',')
                                                    if (ll.length > 0) {
                                                        return (<span><b>Language:</b>&nbsp;
                                                            {ll.map((data: any, i: any) => (
                                                                <>{publicationSingle.data[0].languageName[i][data]}</>
                                                            ))}
                                                        </span>)
                                                    }
                                                }
                                            })()}
                                            {(() => {
                                                if (publicationSingle.data[0].category !== '') {
                                                    let ll = publicationSingle.data[0].category.split(',')
                                                    if (ll.length > 0) {
                                                        return (<span><b>Category:</b>&nbsp;{ll.map((data: any, i: any) => (<>{publicationSingle.data[0].categoryName[i][data]}</>))}</span>)
                                                    }
                                                }
                                            })()}
                                            {(() => {
                                                if (publicationSingle.data[0].publishLocation !== '') {
                                                    let ll = publicationSingle.data[0].publishLocation.split(',')
                                                    if (ll.length > 0) {
                                                        return (<span><b>Published:</b>&nbsp;{ll.map((data: any, i: any) => (<>{publicationSingle.data[0].publishLocationName[i][data]}</>))}</span>)
                                                    }
                                                }
                                            })()}
                                        </div> */}

                                            {(publication.data[0]['name'] === 'Audio' && publicationTrackData.data && publicationTrackData.data.length > 0) ?
                                                <div className="publication-track-table table-responsive mt-5">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <td></td>
                                                                <td>#</td>
                                                                <td>Title</td>
                                                                {/* <td>Singer</td> */}
                                                                {/* <td>Duration</td> */}
                                                                <td style={{ width: '120px' }}></td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {publicationTrackData.data.map((data: any, i: any) =>
                                                            (

                                                                <>
                                                                    <tr className={`${GlobalPlayer.currentPlayAudioId === data.id ? 'active' : ''} ${(GlobalPlayer.audiolist.findIndex((ell: any) => ell.id === data.id) > -1) ? 'bg-off-white' : ''} `}>
                                                                        <td valign="middle">
                                                                            {GlobalPlayer.currentPlayAudioId === data.id ?
                                                                                <span title="Pause Track" className="play-pause-button" onClick={() => pauseTrack(data)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z" /></svg></span> : <span title="Play Track" className="play-pause-button" onClick={() => {
                                                                                    playTrack(data)
                                                                                    /* setTabData({ data: TabDatas.data[i] }) */
                                                                                    /* ShowAudioTab(data.language) */
                                                                                    setAudioTabopen(`${i}`)
                                                                                }} style={{ paddingLeft: '5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M2 24v-24l20 12-20 12z" /></svg></span>}
                                                                        </td>
                                                                        <td valign="middle">{`${i + 1}`}</td>
                                                                        <td valign="middle">
                                                                            <span onClick={() => {
                                                                                /* setTabData({ data: TabDatas.data[i] })
                                                                                setAudioTabopen(`${i}`) */
                                                                                if (myRef.current) {
                                                                                    myRef.current.scrollIntoView({ behavior: "smooth" });
                                                                                }
                                                                            }} style={{ cursor: 'pointer' }}>{data.name}</span>
                                                                            {data.singer !== '' ? <><br /><span className="d-block font-size-16px">{data.singer}</span></> : ''}
                                                                        </td>
                                                                        {/* <td valign="middle">{data.singer}</td> */}
                                                                        {/* <td valign="middle">{data.duration}</td> */}
                                                                        <td valign="middle" style={{ width: '120px' }}>
                                                                            <div className="d-flex flex-row justify-content-center">
                                                                                {/* {JSON.stringify(publicationTrackPDFData.data.filter((e : any) => (e.index === i))[0].pdfSlug)} */}
                                                                                {publicationTrackPDFData.data.filter((e: any) => (e.index === i))[0] !== undefined ?
                                                                                    <Link to={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}books/${publicationTrackPDFData.data.filter((e: any) => (e.index === i))[0].pdfSlug}`} title={data.name}>
                                                                                        <img src={`${process.env.PUBLIC_URL}/icons/pdf.svg`} style={{ marginRight: '-12px' }} alt="pdf" title={data.name} />
                                                                                    </Link> :
                                                                                    ''}

                                                                                {/* <div className="d-flex flex-column justify-content-center">
                                                                                <svg width="6" height="23" viewBox="0 0 6 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M3.02344 0.654297C3.64323 0.654297 4.16276 0.873047 4.58203 1.31055C5.0013 1.75716 5.21094 2.33594 5.21094 3.04688C5.21094 3.76693 5.0013 4.34115 4.58203 4.76953C4.16276 5.20703 3.64323 5.42578 3.02344 5.42578C2.41276 5.42578 1.89779 5.20703 1.47852 4.76953C1.05924 4.34115 0.849609 3.76693 0.849609 3.04687C0.849609 2.33594 1.05924 1.75716 1.47852 1.31055C1.89779 0.873047 2.41276 0.654297 3.02344 0.654297Z" fill="#F94E20" />
                                                                                    <path d="M3.02344 8.9668C3.64323 8.9668 4.16276 9.18555 4.58203 9.62305C5.0013 10.0697 5.21094 10.6484 5.21094 11.3594C5.21094 12.0794 5.0013 12.6536 4.58203 13.082C4.16276 13.5195 3.64323 13.7383 3.02344 13.7383C2.41276 13.7383 1.89779 13.5195 1.47852 13.082C1.05924 12.6536 0.849609 12.0794 0.849609 11.3594C0.849609 10.6484 1.05924 10.0697 1.47852 9.62305C1.89779 9.18555 2.41276 8.9668 3.02344 8.9668Z" fill="#F94E20" />
                                                                                    <path d="M3.02344 17.2793C3.64323 17.2793 4.16276 17.498 4.58203 17.9355C5.0013 18.3822 5.21094 18.9609 5.21094 19.6719C5.21094 20.3919 5.0013 20.9661 4.58203 21.3945C4.16276 21.832 3.64323 22.0508 3.02344 22.0508C2.41276 22.0508 1.89779 21.832 1.47851 21.3945C1.05924 20.9661 0.849609 20.3919 0.849609 19.6719C0.849609 18.9609 1.05924 18.3822 1.47851 17.9355C1.89779 17.498 2.41276 17.2793 3.02344 17.2793Z" fill="#F94E20" />
                                                                                </svg>
                                                                                <div className="dropdown">
                                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{right:0,display:'none'}}>
                                                                                        <span className="dropdown-item" onClick={() => AddQueue(data)} style={{ cursor: 'pointer' }}>Add to queue</span>
                                                                                        <a className="dropdown-item" href="#">Another action</a>
                                                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                                <span style={{ cursor: 'pointer' }} onClick={() => DoDownload(data.musicSrc, data.name)}><img src={`${process.env.PUBLIC_URL}/icons/download.svg`} alt="download" title={`Download ${data.name}`} /></span>
                                                                                <span title={`${(GlobalPlayer.audiolist.findIndex((ell: any) => ell.id === data.id) > -1) ? '' : 'Click to add queue'}`} className="d-flex flex-column justify-content-center" onClick={() => AddQueue(data)} style={{ cursor: 'pointer' }}><i className={`bx bx-add-to-queue ${(GlobalPlayer.audiolist.findIndex((ell: any) => ell.id === data.id) > -1) ? 'already-to-queue' : ''}`} ></i></span>

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {GlobalPlayer.currentPlayAudioId !== '' && GlobalPlayer.currentPlayAudioId === data.id && TabData.data !== undefined && TabData.data.tab_json !== undefined ?
                                                                        <>{TabData.data.tab_json.length > 0 ? <tr>
                                                                            <td colSpan={5}>
                                                                                <div className="tab-panel-container">
                                                                                    <TabPanel Data={TabData.data} titleClass='small-title' tabContentClass="p-0" className="mt-2 mb-2 m-2" />
                                                                                </div>
                                                                            </td>
                                                                        </tr> : ''}</>
                                                                        : ''}
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> : ''}

                                            {(publication.data[0]['name'] === 'Video' && VideoData.data && VideoData.data.length > 0) ?
                                                <ImageWithText Data={VideoData.data} title={`Videos`} className="" DataClass="col-lg-6 col-xl-4" imageWidth="750" imageHeight="460" objectPosition="center" />
                                                : ''}

                                            {/* {JSON.stringify(TabData)} */}
                                            {/* {JSON.stringify(TabDatas)} */}
                                            {/* <div ref={myRef}>
                                            <TabPanel Data={TabData.data} titleClass='small-title' />
                                        </div> */}

                                            {(() => {
                                                /* let PDFURL = ''
                                                if (ChapterSlug !== undefined && PublicationChapterInfo.data.length > 0) {
                                                    if (PublicationChapterInfo.data[0].chapter_url !== '') {
                                                        PDFURL = PublicationChapterInfo.data[0].chapter_url
                                                    }
                                                } else {
                                                    if (publicationSingle.data[0].book_file !== '') {
                                                        PDFURL = publicationSingle.data[0].book_file
                                                    }
                                                } */
                                                if (PDFURL !== '' && PDFURL !== undefined) {
                                                    return (<>
                                                        {(SecChapTitle !== '') ? <div className="d-flex d-lg-none publication-details-container-inner flex-column flex-md-column flex-lg-row justify-content-end mt-0 mb-2 p-0">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 d-flex flex-column">
                                                                <div className={`title-3 w-100`} title={SecChapTitle}>{SecChapTitle}</div>
                                                            </div>
                                                        </div> : ''}
                                                        <iframe className={`publication-details-pdf ${(PublicationSection.data.length > 0 || PublicationChapter.data.length > 0) ? '' : ''} mb-4`} style={{ width: '100%', height: '100vh' }} src={`${process.env.REACT_APP_SITE_URL}pdfjs/web/viewer.html?file=${PDFURL}`}></iframe>
                                                    </>)
                                                }
                                            })()}
                                        </div>
                                    </div>

                                    <div className="d-flex publication-details-related flex-row flex-wrap">
                                        {/* {JSON.stringify(RelatedData)} */}
                                        {RelatedData.data && RelatedData.data.length > 0 && RelatedData.data.map((data: any, i: any) => (
                                            <>
                                                {data.related_data.length > 0 ?
                                                    <div className="related-data">
                                                        <p className="related-title">{data.title}</p>
                                                        <div className="d-flex">
                                                            <Slider className="related-data-carousel" {...options} /* slidesToShow={(data.related_data.length > 2) ? 3 : data.related_data.length} */ navContainerClass="related-nav" >
                                                                {data.related_data && data.related_data.length > 0 && data.related_data.map((dataR: any, i: any) =>
                                                                (
                                                                    <>
                                                                        <Link to={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PUBLICATION_SLUG}${data.slug}/${dataR.categorySlug[0][dataR.category]}/${dataR.slug}`} title={data.title}>
                                                                            <div className="d-flex flex-row justify-content-start related-slider-inner" >
                                                                                <div className="col-3 d-flex flex-column justify-content-center ps-0 pt-0 pt-md-3 pb-0 pb-md-3 pe-0 pe-md-3">
                                                                                    <LazyImage
                                                                                        loadingSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                        actualSrc={`${dataR.banner_image}`}
                                                                                        errorSrc={`${process.env.PUBLIC_URL}/img/placeholder.png`}
                                                                                        width={`100%`}
                                                                                        style={{ position: 'absolute', top: 0, left: 0 }}
                                                                                        ContainerClass="publication-related-image"
                                                                                        imageClass="img-fluid publication-related-image"
                                                                                        title={data.title}
                                                                                        alt={data.title}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-9 col-lg-9 col-xl-8 ps-3 ps-md-0 d-flex flex-column justify-content-center">
                                                                                    <span className="publication-related-name-1">{dataR.title}</span>
                                                                                    {/* <span className="publication-related-name-2"><LimitDesc desc={dataR.desc} limit="30" /></span> */}
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </>
                                                                ))}

                                                            </Slider>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </>
                                        ))}
                                    </div>
                                </ScrollAnimation>
                            </> : (<NoDataFound />)}
                    </div>
                </section>
            </> : (<PageLoader />)}
        </>
    )
}

export default reduxConnect(["player", "playerList"])(Publication);