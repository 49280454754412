import React, { useContext, useEffect, useRef, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, PageLoader, MetaData, getKeyword } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CMSContent from "./CMSContent";

const CMS: React.FC<any> = ({ SiteSlug }: any): any => {
    let { pageSlug } = useParams();
    if (pageSlug === undefined || pageSlug === null || pageSlug === 'undefined' || pageSlug === '') {
        pageSlug = 'home'
    }
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    const [isPageLoad, setisPageLoad] = useState('no')
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [CmsData, setCmsData] = useState({ data: [] as any[] });
    const [PageData, setPageData] = useState({ data: [] as any[] });
    const [IsLikeHomePage, setIsLikeHomePage] = useState('no');
    const [MetaKeyword, setMetaKeyword] = useState('');
    const navigate = useNavigate()
    useEffect(() => {
        setisPageLoad('no')
        setGlobalContent({ ...GlobalContent, IsMediaPlayershow: false })
        setIsLikeHomePage('no')

        axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page/${SiteSlug !== '' && SiteSlug !== undefined ? `site-slug/${SiteSlug}/${pageSlug}` : `slug/${pageSlug}`}`).then(async json => {
            setPageData({ data: json.data.data })
            if (json.data.data.length > 0 && json.data.data[0].metaKeyword !== undefined && json.data.data[0].metaKeyword !== '') {
                setMetaKeyword(await getKeyword(json.data.data[0].metaKeyword) as any)
            }

            if (json.data.data.length === 0 && SiteSlug !== '' && SiteSlug !== undefined) {
                navigate(`${process.env.PUBLIC_URL}/${SiteSlug}`)
            }
            return json.data.data
        }).then(cmsPage => {
            if (cmsPage.length > 0) {
                var site = GlobalContent.AllSiteData.filter((e: any) => e._id === cmsPage[0].siteAccess)
                setGlobalContent({ ...GlobalContent, siteData: site[0], headerClass: '' })
                axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page-content/level1/${cmsPage[0]._id}`).then(async json => {

                    const promises = [] as any;
                    Promise.all(promises).then(dsa => {
                        setCmsData({ data: json.data.data })
                        json.data.data = json.data.data.filter((e: any) => e.status === 'active')
                        if (json.data.data[0] !== undefined && json.data.data[0].type === 'imgRepeat' && json.data.data[0].tSlider === 'full') {
                            setIsLikeHomePage('yes')
                        } else {
                            /* if (json.data.data.length > 0 && json.data.data[0].image_json !== undefined && json.data.data[0].image_json.length === 1) {
                                setIsLikeHomePage('no')
                            } else { */
                            //setIsLikeHomePage('yes')
                            /* } */
                        }

                        if (json.data.data.length > 0 && json.data.data[0].tSlider === "timeline") {
                            setIsLikeHomePage('no')
                        }
                        let BreadCrumbdata_ = [
                            {
                                path: `${process.env.PUBLIC_URL}/`,
                                linkName: 'Home',
                                status: '',
                            }, {
                                path: `${process.env.PUBLIC_URL}/${pageSlug}`,
                                linkName: `${cmsPage[0].title}`,
                                status: '',
                            }]
                        setBreadCrumbdata({ data: BreadCrumbdata_ })
                        document.title = `${(cmsPage[0].metaTitle !== '' && cmsPage[0].metaTitle !== undefined && cmsPage[0].metaTitle !== 'undefined') ? cmsPage[0].metaTitle : cmsPage[0].title}${GlobalContent.pageTitle}`;
                        /* setisPageLoad('yes') */
                    })
                })
            } else {
                navigate(`${process.env.PUBLIC_URL}/404`)
            }
        })
    }, [pageSlug, SiteSlug]);

    const [audioSrc, setaudioSrc] = useState('')
    const myRef = useRef<HTMLAudioElement>(null)

    const startAudio = (src: any, i: any, dI: any) => {
        setaudioSrc(src)
        setTimeout(() => {
            myRef.current?.play();
            let __ = [...CmsData.data]
            let index = -1
            __.forEach((ringtone: any, ij: any) => {
                if (ringtone.references !== undefined) {
                    index = ringtone.references.findIndex((ell: { audioStatus: boolean; }) => ell.audioStatus === true)
                    if (index > -1) {
                        __[ij]['references'][index]['audioStatus'] = false
                    }
                }
            })
            __[dI]['references'][i]['audioStatus'] = true
            setCmsData({ data: __ })
        }, 1);
    }

    const pauseAudio = (src: any, i: any, dI: any) => {
        myRef.current?.pause();
        let __ = [...CmsData.data]
        __[dI]['references'][i]['audioStatus'] = false
        setCmsData({ data: __ })
    }

    const onEndedRingtone = () => {
        let __ = [...CmsData.data]
        let index = -1
        __.forEach((ringtone: any, ij: any) => {
            if (ringtone.references !== undefined) {
                index = ringtone.references.findIndex((ell: { audioStatus: boolean; }) => ell.audioStatus === true)
                if (index > -1) {
                    __[ij]['references'][index]['audioStatus'] = false
                }
            }
        })
        setCmsData({ data: __ })
    }

    return (
        <>
            <section className={`${(isPageLoad === 'no') ? 'd-none' : ''} site-contain  ${(IsLikeHomePage === 'no') ? 'pb-5' : 'p-0'}`}>
                {(() => {
                    if (IsLikeHomePage === 'no') {
                        return (
                            <div className="container">
                                <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                                <SectionTitle title={PageData.data[0]?.title} type="type-2" />
                            </div>
                        )
                    }
                })()}
                <audio ref={myRef} src={audioSrc} onEnded={onEndedRingtone} />
                {PageData.data.length > 0 ?
                    <>
                        <MetaData keywords={MetaKeyword} description={(PageData.data[0]?.metaDescription !== undefined) ? PageData.data[0]?.metaDescription : ''} />
                        <CMSContent SiteSlug={SiteSlug} cmsPageId={PageData.data[0]?._id} siteAccess={(GlobalContent.siteData.is_main === 'no') ? GlobalContent.siteData._id : ``} pageSlug={pageSlug} setisPageLoadFromMain={setisPageLoad} />
                    </>
                    : ''}
            </section>
            {isPageLoad === 'no' ? <PageLoader /> : ''}
        </>
    )
}

export default CMS