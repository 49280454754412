import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { renderToString } from 'react-dom/server';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as _redux from './setup'
import axios from 'axios'
import { Provider } from "react-redux";
import store from "./redux/store";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
const helmetContext = {};

_redux.setupAxios(axios, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjIzOTdkMDMyYzA1MGExOTI2ODFiYTM1IiwidG9rZW4iOlsiNjIzYmVlYzhhZWZmODI1NDJmZDg3ZTU1IiwiNjIzYmVlZTVhZWZmODI1NDJmZDg3ZTViIiwiNjIzYmVlZjZhZWZmODI1NDJmZDg3ZTYyIiwiNjIzYmVmMDNhZWZmODI1NDJmZDg3ZTZhIiwiNjIzYmVmMGZhZWZmODI1NDJmZDg3ZTczIiwiNjIzYmVmMWRhZWZmODI1NDJmZDg3ZTdkIiwiNjIzYmVmMjZhZWZmODI1NDJmZDg3ZTg4IiwiNjIzYmVmMzNhZWZmODI1NDJmZDg3ZTk0IiwiNjIzYmVmM2VhZWZmODI1NDJmZDg3ZWExIiwiNjIzYmVmNDlhZWZmODI1NDJmZDg3ZWFmIiwiNjIzYmVmNWNhZWZmODI1NDJmZDg3ZWJlIiwiNjIzYmVmNmNhZWZmODI1NDJmZDg3ZWNlIiwiNjIzYmVmODFhZWZmODI1NDJmZDg3ZWRmIiwiNjIzYmVmOGRhZWZmODI1NDJmZDg3ZWYxIiwiNjIzYmVmYWFhZWZmODI1NDJmZDg3ZjA0IiwiNjMxMGEwMDU4Y2U0NjcwYjA3ZDNiNGIxIiwiNjMxZGM1NDlmZDVjM2ZlMmU5ZTVkMDFlIiwiNjMyMzA0NzgwZGI1YmEwYjRhOWRjYmQ4IiwiNjMyMzhjZGFiODk5ODFhZDE0NTdhZjU2IiwiNjMyNDE0ODYzNGY4NTYzNDEwMTJkYTc2IiwiNjQxN2UzZWM1MjE4MGU3ZTdmMGExOGQ1IiwiNjQxOTNkYWM2YTY1ZDY4NmVlZjhmMTBhIiwiNjQxOTNlNmM2YTY1ZDY4NmVlZjhmMWFjIiwiNjQxOTNlZTI2YTY1ZDY4NmVlZjhmMjIyIiwiNjQxOTNmNWM2YTY1ZDY4NmVlZjhmMjdjIiwiNjQxOTQwMTY2YTY1ZDY4NmVlZjhmMmVlIiwiNjQxOTQwZWY2YTY1ZDY4NmVlZjhmMzY5IiwiNjQxOTQxMzc2YTY1ZDY4NmVlZjhmM2I5IiwiNjQyNjZmM2M1ZWUxYjZiNTM5NjkyZGYwIiwiNjQyNjc3OGY1ZWUxYjZiNTM5NjkzMGI0IiwiNjQzOGRlNjdkMjcyZjJlZGM4ZTIzYjk0IiwiNjU4NGY3OTFmZjQ1MmNkM2RlZTFjZWQ2IiwiNjU4NGY5NDhmZjQ1MmNkM2RlZTFkMzJjIl0sImlhdCI6MTcwNjA5MTE0OX0.4lGsVz6gKGYkJrqVeO1m1ARuqlGJ9waXzD7Jvk_PXnY')

ReactDOM.render(
  <HelmetProvider context={helmetContext}>
    <Provider store={store}>
      <App>
      </App>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
