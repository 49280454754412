import React, { useContext, useEffect, useState } from "react"
import BreadCrumb from "./sections/BreadCrumb"
import { SectionTitle, CalenderSection, monthNamesShort, monthNames, PrintDesc, Popup, MetaData, GetGujAndEngDateSameDate, getEventCalenderFilters, PrintEventCalenderFiltersSidebar, CalendarNav, CalendarTooltip, GetCmsPopupSlug, GetSiteId } from "./sections/CommonSections";
import GlobalContext from './Core/PageData'
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";

const Calendar: React.FC<any> = ({ language }: any) => {
    let { yearSlug, monthSlug, SiteSlug } = useParams() as any;
    const navigate = useNavigate();
    const { GlobalContent, setGlobalContent } = useContext(GlobalContext as any) as any
    /* const nextEventStartDate = moment().format('YYYY-MM-DD')
    const nextEventEndDate = moment().add(9, 'months').format('YYYY-MM-DD') */
    document.title = `Calendar${GlobalContent.pageTitle}`;
    const [BreadCrumbdata, setBreadCrumbdata] = useState({ data: [] as any[] });
    const [PopUpShow, setPopUpShow] = useState(false);
    const [Years, setYears] = useState({ data: [] as any[] })
    const [CalendarSidebar, setCalendarSidebar] = useState({ data: [{ date: '', sidebarTitle: '', indianDate: '', gujaratiDate: '', events: [], calendar: [], next: '', prev: '', CurrentMonthsDays: [], cmsPage: [] }] as any[] });
    const [nextEvents, setnextEvents] = useState({ data: [{ date: '', sidebarTitle: '', indianDate: '', gujaratiDate: '', events: [], calendar: [], next: '', prev: '', CurrentMonthsDays: [], loading: false, cmsPage: [] }] as any[] });
    const [CategoryUpcomingEventData, setCategoryUpcomingEventData] = useState({ data: [{ date: '', sidebarTitle: '', indianDate: '', gujaratiDate: '', events: [], calendar: [], next: '', prev: '', CurrentMonthsDays: [], loading_: false, cmsPage: [] }] as any[] });

    const [CategoryPastEventData, setCategoryPastEventData] = useState({ data: [{ date: '', sidebarTitle: '', indianDate: '', gujaratiDate: '', events: [], calendar: [], next: '', prev: '', CurrentMonthsDays: [], loading_: false, cmsPage: [] }] as any[] });

    const [toogleTab, settoogleTab] = useState('upcoming');

    var sites = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
    const [currentData, setcurrentData] = useState({ year: parseInt(moment().format('YYYY')), month: moment().format('MM'), date: moment().format('DD'), eventcategory: '', language: language })
    const [EventCategory, setEventCategory] = useState({ data: [] as any[] })
    const pad = (d: any) => {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    const [SubPopupCopyBtn, setSubPopupCopyBtn] = useState(['Copy', 'Copy'])
    const copyCode = (text: any, i: any) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                SubPopupCopyBtn[i] = 'Copied'
                setSubPopupCopyBtn([...SubPopupCopyBtn])
                setTimeout(function () {
                    SubPopupCopyBtn[i] = 'Copy'
                    setSubPopupCopyBtn([...SubPopupCopyBtn])
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const onClose = () => {
        setPopUpShow(false)
    }
    const openSubscribe = () => {
        setPopUpShow(true)
    }

    function getMonths(month: any, year: any) {
        var ar = [];
        var start = moment(year + "-" + month, "YYYY-MMM");
        for (var end = moment(start).add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
            ar.push(start.format('YYYY-MM-DD'));
        }
        return ar;
    }

    useEffect(() => {
        if (window.innerWidth >= 768) {
            const currentDate = moment(currentData.year + '-01-01').format('YYYY-MM-DD')
            const EndDate = moment(currentDate).add(1, 'year').format('YYYY-MM-DD')
            setCategoryUpcomingEventData({ data: [{ ...CategoryUpcomingEventData.data[0], loading_: true }] })
            var CurrentDateTimeStamp = parseInt(moment(`${moment().format('YYYY-MM-DD')}T00:00:00`).format('x'))
            if (currentData.eventcategory !== '') {
                var SiteId = GetSiteId(SiteSlug, GlobalContent.AllSiteData)
                axios.get(`${process.env.REACT_APP_API_URL}calendar/event-category${(SiteId !== '' && SiteId !== undefined && SiteId !== 'undefined') ? '/' + SiteId : ''}/${currentData.eventcategory}/${currentDate}/${EndDate}`).then(async Eventjson => {
                    let promises = getEventCalenderFilters(Eventjson)
                    var CmsPage = [] as any
                    if (promises.CMSSID.length > 0) {
                        CmsPage = await axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page/ids/${promises.CMSSID.join(',')}`).then(json => {
                            return json.data.data
                        })
                    }

                    let upcoming = promises.event.filter((e: any) => CurrentDateTimeStamp <= e.timestamp)
                    setCategoryUpcomingEventData({ data: [{ date: currentDate, sidebarTitle: `Next Events`, indianDate: '', gujaratiDate: '', events: upcoming, calendar: Eventjson.data.data.calender, next: '', prev: '', CurrentMonthsDays: '', loading_: false, cmsPage: CmsPage }] })

                    let Past = promises.event.filter((e: any) => CurrentDateTimeStamp >= e.timestamp)
                    setCategoryPastEventData({ data: [{ date: currentDate, sidebarTitle: `Next Events`, indianDate: '', gujaratiDate: '', events: Past, calendar: Eventjson.data.data.calender, next: '', prev: '', CurrentMonthsDays: '', loading_: false, cmsPage: CmsPage }] })

                    if (upcoming.length > 0) {
                        settoogleTab('upcoming')
                    } else {
                        settoogleTab('past')
                    }

                })
            } else {
                setCategoryUpcomingEventData({ data: [{ ...CategoryUpcomingEventData.data[0], events: [], loading_: false }] })
                setCategoryPastEventData({ data: [{ ...CategoryUpcomingEventData.data[0], events: [], loading_: false }] })
            }
        }
    }, [currentData.eventcategory, currentData.year]);
    useEffect(() => {
        if ((yearSlug !== undefined && isNaN(yearSlug)) || (monthSlug !== undefined && isNaN(monthSlug))) {
            navigate(`${process.env.PUBLIC_URL}/404`)
        }
        setBreadCrumbdata({
            data: [
                {
                    path: `${process.env.PUBLIC_URL}/`,
                    linkName: 'Home',
                    status: '',
                }, {
                    path: `${process.env.PUBLIC_URL}`,
                    linkName: 'Calendar',
                    status: '',
                }
            ]
        })

        if (window.innerWidth < 768) {
            if (monthSlug === undefined) {
                CalendarSidebarHandle(moment().format('YYYY-MM-DD'), '', SiteSlug)
            } else {
                var mm = monthSlug.split('-')
                CalendarSidebarHandle(`${yearSlug}-${monthSlug}-01`, '', SiteSlug)
            }
        } else {
            CalendarSidebarHandle(moment().format('YYYY-MM-DD'), '', SiteSlug)
            CalendarSidebarHandle(moment().format('YYYY-MM-DD'), 'nextEvents', SiteSlug)
        }
        axios.get(`${process.env.REACT_APP_API_URL}event/category/all`).then(json => {
            json.data.data = json.data.data.filter((e: any) => e.status === 'active')
            setEventCategory({ data: json.data.data })
        })
        var yy = [] as any
        for (let yearPrint = parseInt(moment().add(1, 'year').format('YYYY')); yearPrint >= 2005; yearPrint--) {
            yy.push(yearPrint)
        }
        setYears({ data: yy })

        if (SiteSlug !== undefined) {
            var site = GlobalContent.AllSiteData.filter((e: any) => e.site_url === SiteSlug)
            if (site[0].parent_site !== undefined && site[0].parent_site !== '' && site[0].parent_site !== 'undefined') {
                var site = GlobalContent.AllSiteData.filter((e: any) => e._id === site[0].parent_site)
            }
        } else {
            var site = [] as any;
            site = GlobalContent.AllSiteData.filter((e: any) => e.is_main === 'yes')
        }
        setGlobalContent({ ...GlobalContent, siteData: site[0], headerClass: '' })

    }, [monthSlug, yearSlug]);

    const CalendarSidebarHandle = (info: any, Taxonomy = '', SiteSlug = '') => {

        var SiteId = GetSiteId(SiteSlug, GlobalContent.AllSiteData)
        let InfoDate = moment(info)
        var selectedDate = moment(info).format('YYYY-MM-DD')
        var sidebarTitle = `${InfoDate.format('MMMM D YYYY')}`
        var selectedEndDate = moment(info).format('YYYY-MM-DD')
        var subtitle = ''
        var gujaratiDate = ''

        if (Taxonomy === 'nextEvents') {
            selectedDate = moment().format('YYYY-MM-DD')
            selectedEndDate = moment().add(9, 'months').format('YYYY-MM-DD')
        }

        if (window.innerWidth < 768) {
            selectedDate = moment(info).startOf('month').format('YYYY-MM-DD')
            selectedEndDate = moment(info).endOf('month').format('YYYY-MM-DD')
            sidebarTitle = `${moment(info).format('MMMM')}, ${moment(info).format('YYYY')}`
        }
        axios.get(`${process.env.REACT_APP_API_URL}calendar/event${(SiteId !== '' && SiteId !== undefined && SiteId !== 'undefined') ? '/' + SiteId : ''}/${selectedDate}/${selectedEndDate}`).then(json => {
            const promises = getEventCalenderFilters(json)
            if (Taxonomy !== 'nextEvents') {
                let ___ = json.data.data.calender.filter((ell: { englishDate: string; }) => ell.englishDate === selectedDate)
                let Tithi = GetGujAndEngDateSameDate(___)
                subtitle = Tithi.english
                gujaratiDate = Tithi.gujarati
            }

            if (window.innerWidth < 768) {
                let __SGM__ = json.data.data.calender.find((ell: { englishDate: string; }) => ell.englishDate === selectedDate) as any
                let __EGM__ = json.data.data.calender.find((ell: { englishDate: string; }) => ell.englishDate === selectedEndDate) as any

                let _SGM_ = (__SGM__ !== undefined) ? __SGM__.gujaratiIndianMonth : ''
                let _EGM_ = (__EGM__ !== undefined) ? __EGM__.gujaratiIndianMonth : ''

                let _SGMINDIANYEAR_ = (__SGM__ !== undefined) ? __SGM__.gujaratiIndianYear : ''
                let _EGMINDIANYEAR_ = (__EGM__ !== undefined) ? __EGM__.gujaratiIndianYear : ''

                subtitle = (_SGM_ !== '' && _EGM_ !== '') ? `${_SGM_}/${_EGM_} સંવત ${(_SGMINDIANYEAR_ === _EGMINDIANYEAR_) ? _SGMINDIANYEAR_ : `${_SGMINDIANYEAR_} - ${_EGMINDIANYEAR_}`}` : ''

                let _SGME_ = (__SGM__ !== undefined) ? __SGM__.indianDate.split(' ')[0] : ''
                let _EGME_ = (__EGM__ !== undefined) ? __EGM__.indianDate.split(' ')[0] : ''

                let _SGMINDIANYEARE_ = (__SGM__ !== undefined) ? __SGM__.indianYear : ''
                let _EGMINDIANYEARE_ = (__EGM__ !== undefined) ? __EGM__.indianYear : ''

                subtitle += `:::${(_SGME_ !== '' && _EGME_ !== '') ? `${_SGME_}/${_EGME_} Samvat ${(_SGMINDIANYEARE_ === _EGMINDIANYEARE_) ? _SGMINDIANYEARE_ : `${_SGMINDIANYEARE_} - ${_EGMINDIANYEARE_}`}` : ''}`
            } /* else {
                subtitle = json.data.data.calender[0]?.indianDate
            } */

            /* if (json.data.data.calender.length > 0) {
                if (json.data.data.calender[0]?.gujaratiIndianMonth !== '' && json.data.data.calender[0]?.gujaratiIndianPeriod !== '' && json.data.data.calender[0]?.gujaratiIndianDate !== '') {
                    gujaratiDate = `${json.data.data.calender[0]?.gujaratiIndianMonth} ${json.data.data.calender[0]?.gujaratiIndianPeriod} ${json.data.data.calender[0]?.gujaratiIndianDate}`
                }
            } */

            let nextM = moment(info).add(1, 'months').format('YYYY-MM-DD').toUpperCase()
            let prevM = moment(info).subtract(1, 'months').format('YYYY-MM-DD').toUpperCase()

            Promise.all([promises]).then(async eventData => {
                var CmsPage = []
                if (window.innerWidth >= 768 && (Taxonomy === '' || typeof (Taxonomy) === 'object')) {
                    eventData[0].event = eventData[0].event.filter((e: any) => e.start === moment(info).format('YYYY-MM-DD'))
                }

                if (eventData[0].CMSSID.length > 0) {
                    CmsPage = await axios.get(`${process.env.REACT_APP_API_URL}cms/cms-page/ids/${eventData[0].CMSSID.join(',')}`).then(json => {
                        return json.data.data
                    })
                }

                if (Taxonomy == 'nextEvents') {
                    var event = eventData[0].event.sort((a: any, b: any) => (a.start > b.start) ? 1 : -1)
                    setnextEvents({ data: [{ date: selectedDate, sidebarTitle: `Next Events`, indianDate: subtitle, gujaratiDate: gujaratiDate, events: event, cmsPage: CmsPage, calendar: json.data.data.calender, next: nextM, prev: prevM, CurrentMonthsDays: getMonths(InfoDate.format('MMM'), parseInt(InfoDate.format('YYYY'))) }] })
                } else {
                    setCalendarSidebar({ data: [{ date: selectedDate, sidebarTitle: sidebarTitle, indianDate: subtitle, gujaratiDate: gujaratiDate, events: eventData[0].event, cmsPage: CmsPage, calendar: json.data.data.calender, next: nextM, prev: prevM, CurrentMonthsDays: getMonths(InfoDate.format('MMM'), parseInt(InfoDate.format('YYYY'))) }] })
                }
            })
        }).then((Monthtitle) => {

        })
    }
    const ChangeLang = (lang: any, yearSlug: any, monthSlug: any, SiteSlug: any) => {
        if (SiteSlug !== '' && SiteSlug !== undefined && SiteSlug !== 'undefined') {
            navigate(`${process.env.PUBLIC_URL}/${SiteSlug}/calendar/${lang.toLowerCase()}${(yearSlug !== undefined) ? '/' + yearSlug : ''}${(monthSlug !== undefined) ? '/' + monthSlug : ''}`)
        } else {
            navigate(`${process.env.PUBLIC_URL}/calendar/${lang.toLowerCase()}${(yearSlug !== undefined) ? '/' + yearSlug : ''}${(monthSlug !== undefined) ? '/' + monthSlug : ''}`)
        }
    }

    return (
        <>
            <MetaData keywords={`calendar, panchang, tithi, events, swaminarayan, gadi`} description={`Calendar - Maninagar Shree Swaminarayan Gadi Sansthan`} />
            <section className="site-contain pb-5">
                <div className="container">
                    <BreadCrumb BreadCrumbdata={BreadCrumbdata.data} />
                    <SectionTitle title={SiteSlug === 'london' ? 'Kingsbury Mandir Calendar' : 'Shree Swaminarayan Gadi Sansthan Calendar'} titleClassOrg={`mb-4`} type={`title-active-color`} />
                    <div className="row">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 d-none d-md-flex flex-column">
                            {(window.innerWidth >= 768 && (!isNaN(yearSlug) || yearSlug === undefined) && (!isNaN(monthSlug) || monthSlug === undefined)) ? <CalenderSection Data={{ class: 'title-active-color', title: (SiteSlug === 'london') ? 'Kingsbury Mandir Calendar' : 'Shree Swaminarayan Gadi Sansthan Calendar' }} className="title-active-color" CalendarSidebarHandle={CalendarSidebarHandle} openSubscribe={openSubscribe} language={language} monthSlug={monthSlug} yearSlug={yearSlug} SiteSlug={SiteSlug} /> : ''}
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-none d-md-block">
                            {CalendarSidebar.data[0].events && CalendarSidebar.data[0].events.length > 0 ?
                                <div className="border-with-bg-radius calendar-sidebar d-flex flex-column w-100 mb-4">
                                    <h4 className={`calendar-title-date text-center ${(language === 'Gujarati') ? 'font-family-gujarati' : ''}`}>{(language === 'Gujarati') ? `${CalendarSidebar.data[0].calendar[0].gujaratiMonth} ${CalendarSidebar.data[0].calendar[0].gujaratiDate} ${CalendarSidebar.data[0].calendar[0].gujaratiYear}` : CalendarSidebar.data[0].sidebarTitle}</h4>
                                    <p className="calendar-title-guj-date text-center">
                                        {(language === 'Gujarati') ? <span className="font-family-gujarati">{CalendarSidebar.data[0].gujaratiDate}</span> : CalendarSidebar.data[0].indianDate}
                                    </p>
                                    {CalendarSidebar.data[0].events && CalendarSidebar.data[0].events.length > 0 ?
                                        (
                                            CalendarSidebar.data[0].events.map((data: any, i: any) => {
                                                var showViewBtn = 0
                                                if (data.description !== '') {
                                                    //showViewBtn = 1
                                                }
                                                var ccc = {
                                                    data: [
                                                        {
                                                            calender: CalendarSidebar.data[0].calendar
                                                        }
                                                    ]
                                                }
                                                var cmsSlug = GetCmsPopupSlug(data.cmsId, CalendarSidebar.data[0].cmsPage, GlobalContent.AllSiteData)

                                                return (
                                                    <>
                                                        <div className={`calendar-event-sidebar ${data.classNames.join(' ')} d-flex flex-column`}>
                                                            <span className="calendar-event-sidebar-title d-flex flex-row justify-content-between"><span className={`${(language === 'Gujarati') ? 'font-family-gujarati calendar-title-guj-date' : ''}`}>{(language === 'Gujarati') ? data.guj_title : data.title}</span>
                                                                {showViewBtn === 1 ?
                                                                    <Link className="btn btn-primary btn-calendar-sidebar" to={`${process.env.PUBLIC_URL}/events/${CalendarSidebar.data[0].date}`} title="View">{/* LIVE */}View</Link> : ''}
                                                            </span>
                                                            {/* <span className="calendar-event-sidebar-time">Time: 7:00 AM to 10:00 PM</span> */}
                                                            {data.description !== '' && data.description !== 'undefined' && data.description !== undefined ? <div className="calendar-event-sidebar-desc table-responsive" dangerouslySetInnerHTML={{ __html: `${data.description}` }}>{/* <PrintDesc desc={data.description} /> */}</div> : ''}

                                                            <CalendarTooltip title={(language === 'Gujarati') ? data.guj_title : data.title} language={language} CalendarDates={ccc} eventDate={data.start} slug={cmsSlug} desc={(language === 'Gujarati') ? data.mainDescGujarati : data.mainDescEnglish} />
                                                        </div>
                                                    </>
                                                )
                                            })
                                        )
                                        : <div className="text-center m-3">No Events</div>
                                    }

                                    {/* <div className="calendar-event-sidebar deep-sky-blue d-flex flex-column">
                                    <span className="calendar-event-sidebar-title d-flex flex-row justify-content-between"><span>Ranip Patotsav</span><a className="btn btn-primary btn-calendar-sidebar" >LIVE</a></span>
                                    <span className="calendar-event-sidebar-time">Time: 7:00 AM to 10:00 PM</span>
                                    <p className="calendar-event-sidebar-desc">Shree Swaminarayan Mandir, Ranip Patotsav. Murti Pratishtha in the year Samvat 2070 (Friday 3rdApril 2015)</p>
                                </div>
                                <div className="calendar-event-sidebar psychedelic-purple d-flex flex-column">
                                    <span className="calendar-event-sidebar-title d-flex flex-row justify-content-between">Good Friday</span>
                                </div> */}
                                    <div className="text-center">
                                        <Link to={`${process.env.PUBLIC_URL}/events/${CalendarSidebar.data[0].date}`} className="btn btn-4 btn-calendar-sidebar-events mt-1 mb-0" title="View Events">View Events</Link>
                                    </div>
                                </div> : ''}

                            <div className={`calendar-content calendar-sidebar d-flex flex-column w-100 mb-4`}>
                                {/* <h4 className="calendar-title-date text-center">{nextEvents.data[0].sidebarTitle}</h4> */}
                                <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
                                    <select className="form-control calendar-title-date" name="eventcategory" onChange={(e) => {
                                        setcurrentData({ ...currentData, eventcategory: e.target.value })
                                    }}>
                                        <option value="">Upcoming Events</option>
                                        {EventCategory.data && EventCategory.data.length > 0 && EventCategory.data.map((data: any, i: any) => (
                                            <option value={data._id} selected={(data._id === currentData.eventcategory) ? true : false}>{data.name}</option>
                                        ))}
                                    </select>
                                    {currentData.eventcategory !== '' ? <select className="form-control select-2" name="year" onChange={(e) => {
                                        setcurrentData({ ...currentData, year: parseInt(e.target.value) })
                                    }
                                    }>
                                        {Years.data && Years.data.length > 0 && Years.data.map((data: any, i: any) => (
                                            <option value={data} selected={(data === currentData.year) ? true : false}>{data}</option>
                                        ))}
                                    </select> : ''}
                                </div>

                                {currentData.eventcategory === '' && nextEvents.data[0].events && nextEvents.data[0].events.length > 0 ? <PrintEventCalenderFiltersSidebar _data_={nextEvents} language={language} AllSiteData={GlobalContent.AllSiteData} /> : ''}
                                {currentData.eventcategory !== '' ? <>
                                    {CategoryUpcomingEventData.data[0].loading_ ? <div className="text-center m-3">Loading...</div> : <div className="d-flex flex-column gap-2">
                                        {CategoryPastEventData.data[0].events.length > 0 ? <div className="sidebar-toggle d-flex flex-column gap-2" >
                                            <a className="sidebar-toggle-title d-flex flex-row justify-content-between align-items-center bg-white" onClick={() => {
                                                settoogleTab(toogleTab === 'past' ? '' : 'past')
                                            }} title="Past Event">
                                                <span>Past Event</span>
                                                <svg width="13" height="7" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: (toogleTab === 'past' ? 'rotate(0deg)' : 'rotate(180deg)'), transition: '0.2s all' }}>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6525 2.56066L12.1065 12.1066C11.5207 12.6924 10.571 12.6924 9.98519 12.1066L0.439253 2.56066C-0.146534 1.97487 -0.146534 1.02513 0.439253 0.439339C1.02504 -0.146448 1.97479 -0.146448 2.56057 0.439339L11.0459 8.92462L19.5311 0.43934C20.1169 -0.146447 21.0667 -0.146447 21.6525 0.43934C22.2382 1.02513 22.2382 1.97487 21.6525 2.56066Z" fill="black" />
                                                </svg>
                                            </a>
                                            <div className={`${(toogleTab === 'past') ? 'd-flex' : 'd-none'} flex-column gap-2`} style={{ opacity: (toogleTab === 'past' ? '1' : '0'), height: (toogleTab === 'past' ? 'auto' : '0px'), transition: '1s height' }}><PrintEventCalenderFiltersSidebar length={1000} _data_={CategoryPastEventData} language={language} AllSiteData={GlobalContent.AllSiteData} /></div>
                                        </div> : ''}
                                        {CategoryUpcomingEventData.data[0].events.length > 0 ? <div className="sidebar-toggle d-flex flex-column gap-2">
                                            <a className="sidebar-toggle-title d-flex flex-row justify-content-between align-items-center bg-white" onClick={() => {
                                                settoogleTab(toogleTab === 'upcoming' ? '' : 'upcoming')
                                            }} title="Upcoming Event">
                                                <span>Upcoming Event</span>
                                                <svg width="13" height="7" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: (toogleTab === 'upcoming' ? 'rotate(0deg)' : 'rotate(180deg)'), transition: '0.2s all' }}>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6525 2.56066L12.1065 12.1066C11.5207 12.6924 10.571 12.6924 9.98519 12.1066L0.439253 2.56066C-0.146534 1.97487 -0.146534 1.02513 0.439253 0.439339C1.02504 -0.146448 1.97479 -0.146448 2.56057 0.439339L11.0459 8.92462L19.5311 0.43934C20.1169 -0.146447 21.0667 -0.146447 21.6525 0.43934C22.2382 1.02513 22.2382 1.97487 21.6525 2.56066Z" fill="black" />
                                                </svg>
                                            </a>
                                            <div className={`${(toogleTab === 'upcoming') ? 'd-flex' : 'd-none'} flex-column gap-2`} style={{ opacity: (toogleTab === 'upcoming' ? '1' : '0'), height: (toogleTab === 'upcoming' ? 'auto' : '0px'), transition: '1s height' }}><PrintEventCalenderFiltersSidebar length={1000} _data_={CategoryUpcomingEventData} language={language} AllSiteData={GlobalContent.AllSiteData} /></div>
                                        </div> : ''}
                                    </div>}
                                </> : ''}
                            </div>

                            {/* {nextEvents.data[0].events && nextEvents.data[0].events.length > 0 ?
                                <div className="border-with-bg-radius calendar-sidebar d-flex flex-column w-100 gap-2">
                                    <h4 className="calendar-title-date text-center">{nextEvents.data[0].sidebarTitle}</h4>
                                    <PrintEventCalenderFiltersSidebar _data_={nextEvents} language={language} />
                                </div> : ''} */}
                        </div>

                        {(1 === 1/* CalendarSidebar.data[0].events.length > 0 */) ? <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex d-md-none">
                            <div className="calendar-content calendar-sidebar d-flex flex-column w-100">

                                <CalendarNav openSubscribe={openSubscribe} monthSlug={monthSlug} yearSlug={yearSlug} language={language} ChangeLang={ChangeLang} SiteSlug={SiteSlug} />
                                <div className="d-flex flex-row justify-content-between" style={{ alignItems: 'center' }}>
                                    <Link to={`${process.env.PUBLIC_URL}${(SiteSlug !== undefined && SiteSlug !== 'undefined' && SiteSlug !== '') ? '/' + SiteSlug : ''}/calendar${(language !== undefined) ? '/' + language.toLowerCase() : ''}/${moment(CalendarSidebar.data[0].prev).format('YYYY/MM')}`} className="event-details-nav prev" title={moment(CalendarSidebar.data[0].prev).format('MMM YYYY').toUpperCase()}>{(CalendarSidebar.data[0].prev !== '') ? moment(CalendarSidebar.data[0].prev).format('MMM YYYY').toUpperCase() : ''}</Link>
                                    <div>
                                        <h4 className="calendar-title-date text-center">{CalendarSidebar.data[0].sidebarTitle}</h4>
                                        <p className="calendar-title-guj-date text-center font-family-gujarati">{(language.toLowerCase() === 'gujarati') ? CalendarSidebar.data[0].indianDate.split(':::')[0] : CalendarSidebar.data[0].indianDate.split(':::')[1]}</p>
                                    </div>
                                    <Link to={`${process.env.PUBLIC_URL}${(SiteSlug !== undefined && SiteSlug !== 'undefined' && SiteSlug !== '') ? '/' + SiteSlug : ''}/calendar${(language !== undefined) ? '/' + language.toLowerCase() : ''}/${moment(CalendarSidebar.data[0].next).format('YYYY/MM')}`} className="event-details-nav next" title={moment(CalendarSidebar.data[0].next).format('MMM YYYY').toUpperCase()}>{(CalendarSidebar.data[0].next !== '') ? moment(CalendarSidebar.data[0].next).format('MMM YYYY').toUpperCase() : ''}</Link>
                                </div>
                                <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
                                    <select className="form-control calendar-title-date" name="eventcategory" onChange={(e) => {
                                        setcurrentData({ ...currentData, eventcategory: e.target.value })
                                    }} value={currentData.eventcategory}>
                                        <option value="">All Events</option>
                                        {EventCategory.data && EventCategory.data.length > 0 && EventCategory.data.map((data: any, i: any) => (
                                            <option value={data._id} /* selected={(data._id === currentData.eventcategory) ? true : false} */>{data.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {CalendarSidebar.data[0].CurrentMonthsDays && CalendarSidebar.data[0].CurrentMonthsDays.length > 0 ?
                                    (
                                        CalendarSidebar.data[0].CurrentMonthsDays.map((Days: any, i: any) => {
                                            var data = {} as any
                                            var event = [] as any
                                            var ccc = {
                                                data: [
                                                    {
                                                        calender: CalendarSidebar.data[0].calendar
                                                    }
                                                ]
                                            }
                                            var CalenderData = CalendarSidebar.data[0].calendar.filter((ell: { englishDate: string; }) => ell.englishDate === Days)
                                            if (CalenderData.length > 0) {
                                                data = CalendarSidebar.data[0].calendar.filter((ell: { englishDate: string; }) => ell.englishDate === Days)[0]
                                            }
                                            event = CalendarSidebar.data[0].events.filter((ell: { start: string; }) => ell.start === Days)
                                            var showViewBtn = 0
                                            event.forEach((yy: any, j: any) => {
                                                if (yy.description !== '') {
                                                    //showViewBtn = 1
                                                }
                                            })
                                            let mobileCalendarData = { date: '' }
                                            if (language === 'Gujarati') {
                                                mobileCalendarData.date = `${data?.gujaratiDay} ${data?.gujaratiMonth} ${data?.gujaratiDate}, ${data?.gujaratiYear} - ${data?.gujaratiIndianMonth} ${data?.gujaratiIndianPeriod} ${data?.gujaratiIndianDate}`
                                            } else {
                                                mobileCalendarData.date = `${moment(Days).format('dddd MMMM D, YYYY')}${(data?.indianDate !== '' && data?.indianDate !== undefined) ? ` - ${data?.indianDate}` : ''}`
                                            }
                                            if (currentData.eventcategory !== '') {
                                                event = event.filter((ell: any) => ell.categoryId === currentData.eventcategory)
                                            }
                                            return (
                                                <div className=" p-2 d-flex flex-column bg-white">
                                                    <span className="fc-event-sidebar-new-title d-flex flex-row justify-content-between w-100">
                                                        <span className="w-100">
                                                            {/* border-bottom: 2px solid transparent;
                                                                border-image: linear-gradient(0.25turn,rgb(253 185 10), rgba(56,2,155,0));
                                                                border-image-slice: 1; */}

                                                            <span className={`d-block event-day-title ${(event.length > 0) ? 'mb-2' : ''} ${(language === 'Gujarati') ? 'font-family-gujarati calendar-title-guj-date' : ''}`}>
                                                                {mobileCalendarData.date}
                                                            </span>
                                                            {event.map((dataE: any, j: any) => {
                                                                var cmsSlug = GetCmsPopupSlug(dataE.cmsId, CalendarSidebar.data[0].cmsPage, GlobalContent.AllSiteData)
                                                                return (
                                                                    <span className={`${dataE.classNames[0]} fc-event-sidebar d-block  ${((j + 1) === event.length) ? '' : 'mb-2'} ${(language === 'Gujarati') ? 'font-family-gujarati' : ''}`}>

                                                                        <CalendarTooltip title={(language === 'Gujarati') ? dataE.guj_title : dataE.title} language={language} CalendarDates={ccc} eventDate={Days} slug={cmsSlug} desc={(language === 'Gujarati') ? dataE.mainDescGujarati : dataE.mainDescEnglish} />

                                                                        <span className="fc-event-sidebar-title">{/* <i>&#183;</i>  */}{(language === 'Gujarati') ? dataE.guj_title : dataE.title}</span>
                                                                    </span>
                                                                )
                                                            })}
                                                        </span>
                                                        {event.length > 0 && showViewBtn === 1 ? <Link className="btn btn-primary btn-calendar-sidebar" to={`${process.env.PUBLIC_URL}/events/${Days}`} title="View">View</Link> : ''}
                                                    </span>
                                                    {/* <span className="calendar-event-sidebar-time">Time: 7:00 AM to 10:00 PM</span> */}
                                                    {/* <p className="calendar-event-sidebar-desc"><PrintDesc desc={data?.description} /></p> */}
                                                </div>
                                            )
                                        })
                                    )
                                    : <div className="text-center m-3">No Data</div>
                                }

                                {/* <div className="calendar-event-sidebar deep-sky-blue d-flex flex-column">
                                    <span className="calendar-event-sidebar-title d-flex flex-row justify-content-between"><span>Ranip Patotsav</span><a className="btn btn-primary btn-calendar-sidebar" >LIVE</a></span>
                                    <span className="calendar-event-sidebar-time">Time: 7:00 AM to 10:00 PM</span>
                                    <p className="calendar-event-sidebar-desc">Shree Swaminarayan Mandir, Ranip Patotsav. Murti Pratishtha in the year Samvat 2070 (Friday 3rdApril 2015)</p>
                                </div>
                                <div className="calendar-event-sidebar psychedelic-purple d-flex flex-column">
                                    <span className="calendar-event-sidebar-title d-flex flex-row justify-content-between">Good Friday</span>
                                </div> */}
                                {/* <div className="text-center">
                                    <Link to={`${process.env.PUBLIC_URL}/events/${CalendarSidebar.data[0].date}`} className="btn btn-4 btn-calendar-sidebar-events mt-3 mb-3" >View Events</Link>
                                </div> */}
                            </div>
                        </div> : ''}
                    </div>
                </div>
            </section>
            <Popup show={PopUpShow} Body={<div className="font-size-16px">
                <p><strong>Subscribing</strong> to the calendar connects your calender to SwaminarayanGadi.com calendar. Whenever there is a update to the events, your calendar is automatically updated (note, this can take up to 24hrs).</p>
                <SectionTitle title={`Step 1 - Copy one of the links below`} titleClassOrg={`mb-2`} align="justify-content-start" type={`title-active-color`} />
                <div className="ms-3 mb-4 d-flex flex-column gap-1">
                    {SiteSlug === 'london' ? <>
                        <strong>Link for English Calendar:<br /><a title="English Calendar" href={`${process.env.REACT_APP_SITE_URL}london/calendar/ics`} target="_blank" style={{ wordWrap: 'break-word' }}>{process.env.REACT_APP_SITE_URL}london/calendar/ics</a> <span className="btn btn-3" onClick={() => copyCode(`${process.env.REACT_APP_SITE_URL}london/calendar/ics`, 0)}>{SubPopupCopyBtn[0]}</span></strong>
                        <strong>Link for Gujarati Calendar:<br /><a title="Gujarati Calendar" href={`${process.env.REACT_APP_SITE_URL}london/calendar/ics/gujarati`} target="_blank" style={{ wordWrap: 'break-word' }}>{process.env.REACT_APP_SITE_URL}london/calendar/ics/gujarati</a> <span className="btn btn-3" onClick={() => copyCode(`${process.env.REACT_APP_SITE_URL}london/calendar/ics/gujarati`, 1)}>{SubPopupCopyBtn[1]}</span></strong>
                    </> : <>
                        <strong>Link for English Calendar:<br /><a title="English Calendar" href={`${process.env.REACT_APP_SITE_URL}calendar/ics`} target="_blank" style={{ wordWrap: 'break-word' }}>{process.env.REACT_APP_SITE_URL}calendar/ics</a> <span className="btn btn-3" onClick={() => copyCode(`${process.env.REACT_APP_SITE_URL}calendar/ics`, 0)}>{SubPopupCopyBtn[0]}</span></strong>
                        <strong>Link for Gujarati Calendar:<br /><a title="Gujarati Calendar" href={`${process.env.REACT_APP_SITE_URL}calendar/ics/gujarati`} target="_blank" style={{ wordWrap: 'break-word' }}>{process.env.REACT_APP_SITE_URL}calendar/ics/gujarati</a> <span className="btn btn-3" onClick={() => copyCode(`${process.env.REACT_APP_SITE_URL}calendar/ics/gujarati`, 1)}>{SubPopupCopyBtn[1]}</span></strong>
                    </>}
                </div>
                <SectionTitle title={`Step 2 - Subscribe from your calendar application`} titleClassOrg={`mb-2`} align="justify-content-start" type={`title-active-color`} />
                <div className="ms-3 mb-4">
                    <h5>Outlook Calendar</h5>
                    <p className="mb-1">Instructions for subscribing from Outlook.com calendar</p>
                    <ul>
                        <li>Sign in to <strong>Outlook.com</strong></li>
                        <li>At the bottom of the navigation pane, click on the <strong>Calendar icon</strong>.</li>
                        <li>On the toolbar, select <strong>Add calendar &gt; From internet</strong>.</li>
                        <li>In the <strong>Link to the calendar box</strong>, type or copy and paste the calendar <strong>URL</strong> (see above)</li>
                        <li>In the <strong>Calendar name</strong> box, enter a name for the linked calendar eg <strong>Shree Swaminarayan Gadi Calendar</strong></li>
                        <li>Select <strong>Save</strong></li>
                    </ul>
                    <h5>Google Calendar</h5>
                    <p className="mb-1">Instructions for subscribing from Google calendar</p>
                    <ul>
                        <li>Sign in to <strong>calendar.google.com</strong></li>
                        <li>On the left side go to <strong>Other Calendars</strong> and click on the dropdown.</li>
                        <li>Select <strong>Add by URL</strong>.</li>
                        <li>Type or copy and paste the calendar URL (see above)</li>
                        <li>Click on <strong>Add Calendar</strong> and wait for Google to import your events.</li>
                    </ul>
                </div>
            </div>} Header={SiteSlug === 'london' ? 'Kingsbury Mandir Calendar' : 'Subscribe to Maninagar Shree Swaminarayan Gadi Calendar'} onClose={onClose} />
        </>
    )
}

export default Calendar