import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, Route, Routes } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import GlobalContext from './Core/PageData'
import { Content } from './Core/Content'
import { reduxConnect } from "../redux/store";
/* import AudioPlayer from "./AudioPlayer/index"
import AudioPlayerUi from "./AudioPlayer/UI/AudioPlayerUi";
import PlaylistView from './AudioPlayer/UI/PlaylistView' */
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import { ChangeColorCode, ChangeSecondaryColorCode, Popup, SectionTitle } from './sections/CommonSections'
import axios from 'axios'
import CMS from './CMS'
import moment from "moment";
import ReactPlayer from 'react-player'
const Layout: React.FC = (props: any) => {

    var timeZones = moment.tz.names();
    var offsetTmz = [] as any;
    timeZones.forEach(async (trackPdf: any, fff: any) => {
        offsetTmz.push({ code: moment.tz(timeZones[fff]).format('z'), diff: moment.tz(timeZones[fff]).format('Z'), zone: timeZones[fff] })
    })
    offsetTmz.push({ code: 'BST', diff: '+00:00', zone: 'Europe/London' })
    const [GlobalContent, setGlobalContent] = useState({ siteAccess: '', siteLogo: '', IsMediaPlayershow: false, pageTitle: ''/* ' - Maninagar Shree Swaminarayan Gadi Sansthan' */, isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent), siteData: {} as any, AllSiteData: [] as any, AllLanguage: [] as any, AllTimezone: [] as any, SiteUrl: process.env.REACT_APP_SITE_URL, MyTimeCode: moment.tz(moment.tz.guess()).zoneAbbr(), MyTimeZone: moment.tz.guess(), allWorldTimeZone: offsetTmz, headerClass: 'd-none', search: { News: '', Audio: '', Books: '', Pages: '', Events: '' }, VideoPopup: { status: false, VideoUrl: '', VideoTitle: '' } });
    const [GlobalPlayer, setGlobalPlayer] = useState({ currentTime: '', playing: false, audiolist: [], currentPlayAudioId: '', playIndex: 0, playerInstance: '' as any });
    const value = {
        GlobalContent,
        setGlobalContent,
        GlobalPlayer,
        setGlobalPlayer
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const IsFromMobile = searchParams.get('mobile');
    if (location.pathname !== '/manage') {
        props.setPlayer({ show: true });
    } else {
        props.setPlayer({ show: false });
    }

    const onBeforeDestroy = (/* currentPlayId: string, audioLists: [], audioInfo: any */) => {
        return new Promise<void>((resolve, reject) => {
            if (window.confirm('Are you sure you want to close this player?')) {
                resolve()
                setGlobalPlayer({ ...GlobalPlayer, audiolist: [], currentPlayAudioId: '' })
            } else {
                reject()
            }
        })
    }
    const onAudioListsChange = (currentPlayId: any, audioLists: any, audioInfo: any) => {
        if (audioLists.length > 0) {
            var FindAudio = audioInfo.id
            var FindAudioIndex = audioInfo.playIndex
        } else {
            var FindAudio = '' as any
            var FindAudioIndex = 0 as any
        }
        setGlobalPlayer({ ...GlobalPlayer, audiolist: audioLists, currentPlayAudioId: FindAudio, playIndex: FindAudioIndex })
    }
    const onAudioPlay = (audioInfo: any) => {
        setGlobalPlayer({ ...GlobalPlayer, currentPlayAudioId: audioInfo.id, playIndex: audioInfo.playIndex })
    }
    const onAudioPause = (audioInfo: any) => {
        setGlobalPlayer({ ...GlobalPlayer, currentPlayAudioId: '' })
    }

    useEffect(() => {
        /* axios.get(`${process.env.REACT_APP_API_URL}global/site/all`).then(json => {
            return json.data.data
        }).then(temp => {
            axios.get(`${process.env.REACT_APP_API_URL}global/language/all`).then(json => {
                setGlobalContent({ ...GlobalContent, AllLanguage: json.data.data, AllSiteData: temp })
            })
            axios.get(`${process.env.REACT_APP_API_URL}global/time-zone/all`).then(json => {
                setGlobalContent({ ...GlobalContent, AllTimezone: json.data.data })
            })
        }) */

        let BookSlugUrl = [`${process.env.REACT_APP_API_URL}global/site/front/all`, `${process.env.REACT_APP_API_URL}global/language/all`, `${process.env.REACT_APP_API_URL}global/time-zone/all`]
        Promise.all(BookSlugUrl.map((endpoint: any) => axios.get(endpoint))).then((json) => {
            json[0].data.data.map((e: any, i: any) => {
                json[0].data.data[i]['_site_url_'] = e.site_url
            })
            setGlobalContent({ ...GlobalContent, AllSiteData: json[0].data.data, AllLanguage: json[1].data.data, AllTimezone: json[2].data.data })
        })

    }, [])
    ChangeColorCode((GlobalContent.siteData !== undefined) ? GlobalContent.siteData.mainColor : '')
    ChangeSecondaryColorCode((GlobalContent.siteData !== undefined && GlobalContent.siteData !== 'undefined') ? GlobalContent.siteData.secondColor : '')
    useEffect(() => {
        /* if (GlobalContent.siteAccess !== '') {
            axios.get(`${process.env.REACT_APP_API_URL}global/site/${(GlobalContent.siteAccess === '') ? '623beec8aeff82542fd87e55' : GlobalContent.siteAccess}`).then(json => {
                setGlobalContent({ ...GlobalContent, siteLogo: (json.data.data[0].site_logo !== '' && json.data.data[0].site_logo !== undefined) ? json.data.data[0].site_logo : '', siteData: json.data.data[0] })
            })
        } else {
            setGlobalContent({ ...GlobalContent, siteAccess: '623beec8aeff82542fd87e55' })
        } */
        /* console.log(GlobalContent.siteData)
        var site = GlobalContent.AllSiteData.filter((e: any) => e._id === '623beec8aeff82542fd87e55')[0]
        setGlobalContent({ ...GlobalContent, siteLogo: (site.site_logo !== '' && site.site_logo !== undefined) ? site.site_logo : '' }) */


    }, [GlobalContent.siteData])

    const icon = {
        pause: <span title="Pause Track" className="play-pause-button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z" /></svg></span>,
        play: <span title="Pause Track" className="play-pause-button" style={{ paddingLeft: '5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M2 24v-24l20 12-20 12z" /></svg></span>,
        loading: <span title="Pause Track" className="play-pause-button" style={{ opacity: 0.5 }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"></svg></span>,
        next: <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.2335 0.405273C22.6683 0.405273 23.0208 0.757773 23.0208 1.19255V24.8088C23.0208 25.2435 22.6683 25.596 22.2335 25.596H20.6593C20.2245 25.596 19.872 25.2435 19.872 24.8088V1.19255C19.872 0.757773 20.2245 0.405273 20.6593 0.405273H22.2335ZM0.979004 24.7803C0.979004 25.4341 1.73013 25.8028 2.24746 25.403L17.4908 13.624C17.8985 13.3089 17.8985 12.6933 17.4908 12.3783L2.24746 0.599292C1.73009 0.199423 0.979004 0.568126 0.979004 1.22201V24.7803Z" fill="#323232" />
        </svg>,
        prev: <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.76647 0.405273C1.3317 0.405273 0.979198 0.757773 0.979198 1.19255V24.8088C0.979198 25.2435 1.3317 25.596 1.76647 25.596H3.34068C3.77545 25.596 4.12795 25.2435 4.12795 24.8088V1.19255C4.12795 0.757773 3.77545 0.405273 3.34068 0.405273H1.76647ZM23.021 24.7803C23.021 25.4341 22.2699 25.8028 21.7525 25.403L6.50919 13.624C6.10152 13.3089 6.10152 12.6933 6.50919 12.3783L21.7525 0.599292C22.2699 0.199423 23.021 0.568126 23.021 1.22201V24.7803Z" fill="#323232" />
        </svg>
    }
    const [PopUpShow, setPopUpShow] = useState({ status: false, VideoUrl: '' });
    return (
        <>
            {GlobalContent.AllSiteData.length > 0 && GlobalContent.AllLanguage.length > 0 && GlobalContent.AllTimezone.length > 0 ?
                <GlobalContext.Provider value={value}>
                    {IsFromMobile === null ? <Header className={GlobalContent.headerClass} /> : 
                        <style>
                            {`body{padding-top:0px;}
                            nav[aria-label="breadcrumb"]{display:none !important}`}
                        </style>
                    }
                    <Content>
                        <Outlet />
                    </Content>
                    {IsFromMobile === null ? <Footer /> : ''}
                    {(GlobalContent.IsMediaPlayershow === true) ? (
                        <div style={{ background: 'red', height: '50px', position: 'fixed', width: '100%', bottom: '0px', zIndex: '3' }}></div>
                    ) : <></>}
                    {/* <AudioPlayer /> */}
                    {/* <PlaylistView/> */}
                    {/* {props.player.show && props.playerList.list.length > 0 && (
                        <AudioPlayerUi />
                    )} */}
                    {GlobalPlayer.audiolist.length > 0 ? <ReactJkMusicPlayer audioLists={GlobalPlayer.audiolist} autoPlay={true} showDownload={false} showThemeSwitch={false} mode={'full'} showDestroy={true} playIndex={GlobalPlayer.playIndex} className="music-container" onBeforeDestroy={onBeforeDestroy} onAudioListsChange={(currentPlayId: any, audioLists: any, audioInfo: any) => onAudioListsChange(currentPlayId, audioLists, audioInfo)} onAudioPlay={(audioInfo) => onAudioPlay(audioInfo)} defaultPosition={{ right: 30, bottom: 30 }} onAudioPause={onAudioPause} spaceBar={true} icon={icon} getAudioInstance={(instance) => { setGlobalPlayer({ ...GlobalPlayer, playerInstance: instance }) }} /> : ''}
                </GlobalContext.Provider> :
                <div className='d-flex justify-content-center align-items-center bg-off-white' style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%', }}>
                    {/* <SectionTitle title={`Jay Shree Swaminarayan`} titleClassOrg={`mb-4`} type={`title-active-color`} /> */}
                </div>}

            <Popup show={GlobalContent.VideoPopup.status} Body={<div className="font-size-16px">
                <ReactPlayer
                    className='border-radius-15px d-flex flex-column overflow-hidden black-color-bg'
                    url={GlobalContent.VideoPopup.VideoUrl}
                    width='100%'
                    height={`70vh`}
                    controls={true}
                    playsinline={true}
                    playing={true}
                    volume={1}
                    muted={false}
                />
            </div>} Header={GlobalContent.VideoPopup.VideoTitle} onClose={() => {
                setGlobalContent({ ...GlobalContent, VideoPopup: { ...GlobalContent.VideoPopup, status: false, VideoUrl: '', VideoTitle: '' } })
            }} />
        </>
    );
};

export default reduxConnect(["player", "playerList"])(Layout);
